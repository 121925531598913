import Vue from "vue";
import Router from "vue-router";

import store from "@/store";
import KbCookie from "@/classes/KbCookie.js";

Vue.use(Router);

const router = new Router({
	mode: "history",
	base: process.env.BASE_URL, //NOTE: Reads from .env.xxx
	scrollBehavior: (to, from, savedPosition) =>
	{
		if (to.hash)       { return {selector:to.hash}; }
		if (savedPosition) { return savedPosition;      }
		
		return {x:0, y:0};
	},
	routes: [
		


		_moduleRoutes_custom_one("catalog",  "catalog",  "Catalogue","Catalog",        "mdi-view-quilt", false, false, true, true, false, true),
		_moduleRoutes_custom_one("cart",  "cart",  "Mon panier","Cart",        "mdi-cart-outline", true, false, false, true, false, true),
		_moduleRoutes_custom_one("orders",  "orders",  "Mes commandes","My orders",        "mdi-file-multiple", true, false, false, true, false, true),
		_moduleRoutes_custom_one("account",  "account",  "Mon compte","My account", "mdi-account-cog", true, false, false, true, false, true),
		_moduleRoutes_custom_one("registration",  "registration",  "Nouveau compte","New account", "mdi-account-plus", false, false, true),
		_moduleRoutes_custom_one("login",  "login",  "Se connecter","Log in", "mdi-login", false, false, true),
		_moduleRoutes_custom_one("product",  "product",  "Produit","Product",        "", false, false, false, false, false, true),
		_moduleRoutes_custom_externalLink("contact-us",  "https://www.t4s2009.com/fr/demande-dinformation/",  "Contactez-nous","Contact us",        "mdi-email", true, true),
		_moduleRoutes_custom_externalLink("facebook",  "https://www.facebook.com/tiges4saisons/",  "Facebook","Facebook",        "mdi-facebook", true, true),
		_moduleRoutes_custom_one("order",  "order",  "", "", "", true,false, false, false,false, true),
		
		
		_moduleRoutes_custom_one("admin-users",  "admin/users",  "Liste des clients","Clients list", "mdi-account-multiple", false, true, false, false, true, true),
		_moduleRoutes_custom_one("admin-createUser",  "admin/createUser",  "Nouveau client","New client", "", false, true, false, false, false, true),
		_moduleRoutes_custom_one("admin-orders",  "admin/orders",  "Commandes passées","Previous orders", "mdi-file-multiple", false, true, false, false, true, true),
		_moduleRoutes_custom_one("admin-sendEmail",  "admin/sendEmail",  "Envoyer un courriel","Send email", "mdi-email-plus", false, true, false, false, true, true),
		
		_moduleRoutes_custom_one("admin-order",  "admin/order",  "Commande #","Order #", "", false, true),
		_moduleRoutes_custom_one("admin-user",  "admin/user",  "", "", false, true),
		_moduleRoutes_custom_one("admin-user-favorites",  "admin/user/favorites",  "", "", false, true),
		_moduleRoutes_custom_one("admin-user-orders",  "admin/user/orders",  "", "", false, true),
		_moduleRoutes_custom_one("admin-sudoStart",  "admin/sudoStart",  "", "", false, true),

		_moduleRoutes_custom_one("logout",  "logout",  "Se déconnecter","Log out",        "mdi-logout", false, false, false, true, true),
		
		{path:"*", redirect:"/catalog"},
	]
});



	router.beforeEach((to, from, next) =>
	{
		if(store.state.user.firstVisit) {
			store.state.user.firstVisit = false;
			const cookie = new KbCookie();
			if(cookie.isLoggedIn === true) {
				store.commit('user_setLoggedIn', cookie.exportToObject());
			} else {
				cookie.delete();
			}
		}
		if ((store.state.user.isLoggedIn !== true && to.meta?.loginAccess === true) || (!store.state.user.isAdmin && to.meta?.adminAccess === true))
		{
			next({name:"login"});
		}
		else { next(); }
	});


export default router;






	//
	// NOTES:
	//		Don't try to do something like:
	//			#1
	//				const sPath = /* webpackMode: "eager" */// `@/views/demoPages/${pageName}/Index.vue`);
	//				const sPath = /* webpackMode: "eager" */// "@/views/demoPages/"+pageName+"/Index.vue");
	//				import(sPath);
	//			#2
	//				import(/* webpackMode: "eager" */// `@/views/demoPages/${pageName}/Index.vue`));
	//		Because either ESLint or Webpack will complain and won't know how to resolve the paths.
	//		Side effects:
	//			Upon build, webpack will walk through everything inside "@/aaa/bbb/ccc"+variableStuff, and create distinct bundle for each file, "in case" we'll need them.
	//			The prob, is that if we've got a syntax err in any of the files in that dir, we'll get a super hard to debug err
	//			Also, not sure it'll actually allow tree shaking...
	// https://github.com/webpack/webpack/issues/6680#issuecomment-370800037
	// function _moduleRoutes_formList_one(routerPath, componentSubPath, label, icon)
	// {
	// 	return {
	// 		path: `/${routerPath}/`,
	// 		meta: {showInMenu:true, label, icon},
	// 		component: () => import("@/layouts/modules/Index.vue"),
	// 		children: [
	// 			//https://router.vuejs.org/guide/essentials/passing-props.html#object-mode
	// 			{path:":pk", meta:{labelAsParam:"pk"}, component: () => import(/* webpackMode: "eager" */ "@/views/modules/"+componentSubPath+"/form/Index.vue")},
	// 			{path:"",    meta:{labelAsParam:null}, component: () => import(/* webpackMode: "eager" */ "@/views/modules/"+componentSubPath+"/list/Index.vue")},
	// 		]
	// 	};
	// }
	function _moduleRoutes_custom_one(routerName, routerPath, label_fr,label_en, icon, loginAccess = true, adminAccess = true, showInAnonymeMenu=false, showInLoginMenu=false, showInAdminMenu=false, showLabelPage=false)
	{
		return {
			path: `/${routerPath}`,
			meta: {label_fr,label_en, icon, loginAccess, adminAccess, showLabelPage, showInAnonymeMenu, showInLoginMenu, showInAdminMenu},
			component: () => import("@/layouts/modules/Index.vue"),
			children: [
				//https://router.vuejs.org/guide/essentials/passing-props.html#object-mode
				{name: routerName, path: "", component: () => import(/* webpackMode: "eager" */ "@/views/modules/"+routerPath+"/Index.vue"), meta: {loginAccess, adminAccess}},
			]
		};
	}

	function _moduleRoutes_custom_externalLink(routerPath, url, label_fr,label_en, icon, showInAnonymeMenu=false, showInLoginMenu=false, showInAdminMenu=false)
	{
		return {
			path: `/${routerPath}`,
			meta: {label_fr,label_en, icon, newWindow:true, showInAnonymeMenu, showInLoginMenu, showInAdminMenu},
			beforeEnter() {location.href = url}
		};
	}
