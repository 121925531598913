import Vue from "vue";
import Vuex from "vuex";

import router from "@/router";
import KbCookie from "@/classes/KbCookie.js";
import KbApi from "@/classes/KbApi.js";

import i18n from "@/loc/kb-loc-setup";

import { vuetify } from "@/main.js";

Vue.use(Vuex);


const oStore = new Vuex.Store({
	//ROOT CONTEXT STUFF
	state: {
		user: {
			isLoggedIn: false,
			isAdmin: false, //Ex for "Ce profil doit être revu par l'admin"
			displayName:   "",
			token_public: "",
			token_private: "",
			nbProductCart: 0,
			avatar: "mdi-account",
			lang: "fr",
			prefs: {
				leftDrawerDefaultVisibility: "full", //hidden|mini|full. NOTE: If on mobile though, will always start closed
				rightDrawerDefaultVisibility: "hidden", //hidden|show. NOTE: If on mobile though, will always start closed
			},
			firstVisit: true,
		},
		rightDrawer: {
			visible: false,
			type: null, //options | moduleListFilters (for KbModuleList_Filters) | something else
			moduleListFilters: null, //Instance of KbModuleList_Filters, when type is "moduleListFilters"
		},
		debug: {
			showColorPalette: true,
		}
	},
	//Mutations are synchronous
	mutations: {
		user_setLoggedIn(oRootState, oPayload) {
			oRootState.user.isLoggedIn = true;
			oRootState.user.isAdmin = oPayload.isAdmin;
			oRootState.user.displayName = oPayload.displayName;
			oRootState.user.token_public = oPayload.token_public;
			oRootState.user.token_private = oPayload.token_private;
			oRootState.user.nbProductCart = oPayload.nbProductCart;
			oRootState.user.lang = oPayload.lang;
			
			i18n.kb_lang_switchAsync(oPayload.lang);
			KbApi.lang = oPayload.lang;
			vuetify.framework.lang.current = oPayload.lang; //NOTE: There's supposed to be a Vue.prototype.$vuetify prop but it's not there, so do it outside of Vuex...
		},
		user_setLoggedOut(oRootState) {
			oRootState.user.isLoggedIn = false;
			oRootState.user.isAdmin = false;
			oRootState.user.displayName = "";
			oRootState.user.token_public = "";
			oRootState.user.token_private = "";
			oRootState.user.nbProductCart = 0;
		},
		user_updateCartQty(oRootState, newQty) {
			oRootState.user.nbProductCart = newQty;
		},
		update_cookie(oRootState) {
			new KbCookie(oRootState.user).save();
		},
		rightDrawer_visible(oRootState, oPayload) { oRootState.rightDrawer.visible = oPayload; },
		rightDrawer_setType_none(oRootState, oPayload)
		{
			oRootState.rightDrawer.type              = null;
			oRootState.rightDrawer.moduleListFilters = null;
		},
		rightDrawer_setType_options(oRootState, oPayload)
		{
			oRootState.rightDrawer.type              = "options";
			oRootState.rightDrawer.moduleListFilters = null;
		},
		rightDrawer_setType_moduleListFilters(oRootState, oPayload)
		{
			oRootState.rightDrawer.moduleListFilters = oPayload;
			oRootState.rightDrawer.type              = "moduleListFilters";
		},
	},
	//Actions can be async
	actions: {
		user_logIn(oRootContext, oPayload)
		{
			oRootContext.commit("user_setLoggedIn", oPayload);
			oRootContext.commit("update_cookie");
			
			router.push({name: ( oPayload?.isAdmin ? 'admin-users' : 'catalog')});
		},
		user_logOut(oRootContext, oPayload)
		{
			oRootContext.commit("user_setLoggedOut");
			new KbCookie().delete();
			router.push({path:"/login"});
		},
		user_cartQty(oRootContext, newQty)
		{
			oRootContext.commit("user_updateCartQty", newQty);
			oRootContext.commit("update_cookie");
		},
		rightDrawer_close(oRootContext, oPayload)
		{
			oRootContext.commit("rightDrawer_visible", false);
			oRootContext.commit("rightDrawer_setType_none");
		},
		rightDrawer_showOptions(oRootContext, oPayload)
		{
			oRootContext.commit("rightDrawer_setType_options");
			oRootContext.commit("rightDrawer_visible", true);
		},
		rightDrawer_showModuleListFilters(oRootContext, oPayload)
		{
			oRootContext.commit("rightDrawer_setType_moduleListFilters", oPayload);
			oRootContext.commit("rightDrawer_visible", true);
		},
	},
});


export default oStore;
