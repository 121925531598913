<template>
	
	<v-container class="fill-height secondary--text">
		<v-row>
			<v-col lg="6" offset-lg="3" md="8" offset-md="2" sm="8" offset-sm="2">

				<v-row>
					<v-col>{{ $t('search') }}</v-col>
				</v-row>
				<v-row>
					<v-col class="py-0">
						<v-text-field outlined v-model="search" append-icon="mdi-magnify" @click:append="goToPage" class="rounded-0" background-color="white" @change="goToPage()" :placeholder="$t('searchLabel')" />
					</v-col>
				</v-row>
				<v-row class="mt-0 mb-6">
					<v-col offset="1" cols="5" class="py-0">
						<v-checkbox class="my-0" v-model="filter.isActivated" :label="$t('active')" color="primary" hide-details @change="goToPage()" />
					</v-col>
					<v-col offset="1" cols="5" class="py-0">
						<v-checkbox class="my-0" v-model="filter.isNoActived" :label="$t('inactive')" color="primary" hide-details @change="goToPage()" />
					</v-col>
					<v-col offset="1" cols="5" class="py-0">
						<v-checkbox v-model="filter.isApproved" :label="$t('approved')" color="primary" hide-details @change="goToPage()" />
					</v-col>
					<v-col offset="1" cols="5" class="py-0">
						<v-checkbox v-model="filter.isNoApproved" :label="$t('notApproved')" color="primary" hide-details @change="goToPage()" />
					</v-col>
				</v-row>
				<v-row v-if="pageLoading" justify="center">
					<v-progress-circular :size="200" color="primary" indeterminate class="my-16" />
				</v-row>
				<v-row v-else>
					<v-col v-for="loop_user in users" :key="loop_user.pk"
						cols="12">
						<v-card :class="`rounded-0 pa-2 secondary--text kb-card-user-${loop_user.isActivated && loop_user.isApproved ? 'active' : 'inactive'}`" flat>
							<v-col cols="12" class="text-right font-weight-medium pa-0 caption">
								<span v-if="loop_user.isApproved" class="primary--text">{{ $t('approved') }}</span>
								<span v-else>{{ $t('notApproved') }}</span>
								<br>
								<span v-if="!loop_user.isActivated">{{ $t('inactive') }}</span>
							</v-col>
							<v-col cols="12" class="pa-0 pl-7">
								<span class="secondary--text text-h6">{{ loop_user.companyName }}</span>
								<br><span class="subtitle-1">{{ loop_user.contactName }}</span>
								<br>{{ loop_user.phone }}
								<br>{{ loop_user.email }}
							</v-col>
							<v-card-actions>
								<v-col cols="12" md="6" offset-md="6">
									<v-btn cols="3" depressed color="secondary" width="100%" class="py-5 my-2" :to="`/admin/user?id=${loop_user.pk}`">{{ $t('fullProfile') }}</v-btn>
								</v-col>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>

				<v-row justify="center">
					<v-pagination v-model="paging.currentPage" :length="paging.pageCount" :total-visible="7" @input="goToPage(paging.currentPage)" />
				</v-row>		
						
			</v-col>
		</v-row>

		<v-row v-if="errMessage" class="mt-5" justify="center">
			<v-col class="py-0">
				<v-alert outlined type="error" text>{{ errMessage }}</v-alert>
			</v-col>
		</v-row>
	</v-container>
	
</template>
<script>
	
	import KbApi from "@/classes/KbApi.js";

	export default {
		i18n: {
			messages: {
				fr: {
					search: `RECHERCHE`,
					approved: `APPROUVÉ`,
					notApproved: `NON APPROUVÉ`,
					inactive: `INACTIF`,
					active: `ACTIF`,
					fullProfile: `Fiche complète`,
					searchLabel: `Nom du contact ou de l'entreprise, # client, courriel, téléphone`,
				},
				en: {
					search: `SEARCH`,
					approved: `APPROVED`,
					notApproved: `NOT APPROVED`,
					inactive: `INACTIVE`,
					active: `ACTIVE`,
					fullProfile: `FULL PROFILE`,
					searchLabel: `Contact or entreprise name, client #, email, phone`,
				},
			}
		},
		data() {
			return {
				pageLoading:true,
				search:"",
				users:[],
				paging:{},
				lastQsa:"",
				filter: {
					isActivated: true,
					isNoActived: false,
					isApproved: false,
					isNoApproved: false,
				},
				errMessage: "",
			}
		},
		mounted() {
			let pageIndexFromQsa = this.$route.query.pageIndex;
			this.paging.pageIndex = (typeof pageIndexFromQsa !== 'undefined') && (pageIndexFromQsa = parseInt(pageIndexFromQsa, 10)) > 1 ? pageIndexFromQsa : 1;
			this.loadUsers();
		},
		methods: {
			goToPage(page = 1) {
				this.paging.pageIndex = page
				this.loadUsers();
			},
			async loadUsers()
			{
				this.pageLoading = true;
				const qsa = {
					pageSize: 5
				};

				if(this.paging.pageIndex > 1) {
					qsa.pageIndex = this.paging.pageIndex;
				}

				if(this.search) {
					qsa.search = this.search;
				}

				for (const [key, value] of Object.entries(this.filter)) {
					if(value) {
						qsa[key] = true;
					}
				}

				try {
					if(JSON.stringify(qsa) !== JSON.stringify(this.lastQsa)) {
						this.lastQsa = qsa;
						const data = await KbApi.get('/api/users', this.$store.state.user, qsa);
						this.users = data.items;
						this.paging = data.paging;
						window.scrollTo(0, 0);
					}
				} catch (error) {
					this.errMessage = this.$KbUtils.parseApiError(error);
				} finally {
					this.pageLoading = false;
				}
			},
		},
	};
	
</script>

<style scoped>
	
	.kb-card-user-active {
		border: 3px solid var(--v-primary-base);
	}
	.kb-card-user-inactive {
		border: 3px solid var(--v-secondary-base);
	}
	
</style>
