import axios from "axios";
import i18n from "@/loc/kb-loc-setup";

export default class KbUtils
{
	static get IMG_LAZY_SRC_GREY_16X16() { return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAIAAACQkWg2AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZSURBVDhPY3hAIhjVQAwY1UAMGHQaHjwAAD9boB9HiJ0WAAAAAElFTkSuQmCC"; }
	static get FAKE_SLEEP_DURATION()     { return 500; }
	
	static async fakeSleep(duration=KbUtils.FAKE_SLEEP_DURATION)
	{
		return new Promise((s,f) =>
		{
			setTimeout(s, duration);
		});
	}

	/*
	Ex we got:
		const a = Symbol("bob")
		Yields "bob"
	*/
	static symbolVal(symbol) { return symbol.description; }
	
	static ellipsis(text, maxLength)
	{
		return text.length<=maxLength ? text : text.substring(0,maxLength-3)+"...";
	}
	
	//Rets a localized err string
	static parseApiError(error)
	{
		const status = error.response?.status || null;
		
		switch (i18n.locale)
		{
			case "fr": return status ? `Une erreur ${status} est survenue, merci de ressayer` : `Une erreur inconnue est survenue, merci de ressayer`;
			case "en": return status ? `A ${status} error occured, please try again`          : `An error occured, please try again`;
		}
	}
	
	/*
	Rets a map of fieldName => fieldVal, only for fields with $vuelidate validation props
	NOTE:
		validations: {} works with props too, as long as it's a class instance:
			props: {
				instance: {type:Person, required:true},
			},
			validations: {
				instance: {
					firstName: {required},
					lastName: {required},
				},
	WARNING:
		If our dataset doesn't have validation specs for each fields, we'll need to manually include what's missing
		If we've got PKs, since they'll never change, they'll never be flagged as $dirty, so we should maybe always include them anyways
		For arrs:
			According to the docs, in validations:{}, we need to make it as {$each:{}} for it to work
			If we change [] to [1,2,3], it won't make it $dirty=true, unless the items or the array itself has validators (?)
	Usage ex:
		import { required, minLength, email } from "vuelidate/lib/validators";
		export default {
			validations: {
				//NOTE: Instead of {...}, we should put stuff like {required} or {required,email} for ex
				testNested: {
					arr: {
						$each: {
							a: {...},
							b: {...},
							c: [{...}],
						},
					},
					val: {...},
					sub: {
						a:{...},
						b:{...},
						c:{...}
					},
				},
			},
			data() {
				return {
					testNested: {
						arr: [
							{
								a: 1,
								b: 2,
								c: [3,4,5],
							},
							{
								a: 6,
								b: 7,
								c: [8],
							}
						],
						val: 4,
						sub: {
							a:1,
							b:2,
							c:3
						},
					},
				};
			},
			methods: {
				test()
				{
					const fieldsWithChanges = KbUtils.getDirty(this.$v.testNested);
				},
	*/
	static validation_getDirtyFields(vuelidateGroup, deep=false)
	{
		//If it's an arr
		if (vuelidateGroup.$each)
		{
			const dirtyArr = [];
			
			for (const loop_idx in vuelidateGroup.$each.$iter)
			{
				const loop_item = vuelidateGroup.$each.$iter[loop_idx];
				if (loop_item.$anyDirty) { dirtyArr.push(KbUtils.validation_getDirtyFields(loop_item,deep)); }
			}
			
			return dirtyArr;
		}
		//Else it's an obj
		else
		{
			const dirtyObj = { };
			
			for (const loop_propName in vuelidateGroup.$params)
			{
				const loop_propVal = vuelidateGroup[loop_propName];
				
				//If that child's an arr / has sub struct
				if (loop_propVal.$each)
				{
					if (deep) { dirtyObj[loop_propName] = KbUtils.validation_getDirtyFields(loop_propVal,deep); }
				}
				//If it's a normal val
				else if (loop_propVal.$dirty)
				{
					dirtyObj[loop_propName] = loop_propVal.$model;
				}
			}
			
			return dirtyObj;
		}
	}
};




/*
Allows to do:
	import { KbUtilsPlugin } from "@/classes/KbUtils.js";
	Vue.use(KbUtilsPlugin, {someStuff:"bob"}); //options.bob
	
	And do this.$KbUtils everywhere + in templates
*/
const KbUtilsPlugin = {
	install(Vue, options)
	{
		Vue.prototype.$KbUtils = KbUtils;
	},
};
export { KbUtilsPlugin };
