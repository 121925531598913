<template>
	
	<v-container class="fill-height secondary--text">
		<v-row v-if="pageLoading" justify="center">
			<v-progress-circular :size="200" color="primary" indeterminate class="my-16" />
		</v-row>
		<v-row v-else>
			<v-col lg="6" offset-lg="3" md="8" offset-md="2" sm="8" offset-sm="2">

				<v-row>
					<v-col class="text-center">
						<h1 class="text-center py-6 font-weight-regular secondary--text">{{ $t('order') }} #{{ order.pk }}</h1>
						{{ order.creation_date }}
					</v-col>
					<v-col cols="12">
					</v-col>
				</v-row>
				
				<v-row>
					<v-col cols="12" class="text-uppercase font-weight-medium">{{ $t('generalInfo') }}</v-col>
					<v-col cols="12">

					<v-card class="rounded-0 pa-5 secondary--text" flat>
						{{ order.user.companyName }}
						<br>{{ order.user.contactName }}
						<br>{{ order.user.email }}
						<br>{{ order.user.phone }}
					</v-card>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" class="text-uppercase font-weight-medium">{{ $t('deliveryAddresses') }}</v-col>
					<v-col cols="12">
						<v-card class="rounded-0 pa-5 secondary--text" flat>
							<v-row>
								<v-col cols="12" sm="6">
									<v-card-title class="pa-0">{{ $t('deliveryAddress') }}</v-card-title>
									<v-select v-model="order.deliveryAddress.pk" dense :items="deliveryAddresses" item-text="addressName" item-value="pk" :disabled="!isEditable" background-color="white" />
									<div v-if="selectedDeliveryAddress">
										{{ selectedDeliveryAddress.address }}
										<br>{{ selectedDeliveryAddress.city }}
										<br>{{ selectedDeliveryAddress.state }}
										<br>{{ selectedDeliveryAddress.postalCode }}
										<br>{{ selectedDeliveryAddress.country }}
										<template v-if="selectedDeliveryAddress.notes">
											<br><b>{{ $t('notes') }}:</b> {{ selectedDeliveryAddress.notes }}
										</template>
									</div>
								</v-col>
								<v-col cols="12" sm="6">
									<v-card-title class="pa-0">{{ $t('billing') }}</v-card-title>
									<div class="mt-12">
										{{ order.billingAddress.address }}
										<br>{{ order.billingAddress.city }}
										<br>{{ order.billingAddress.state }}
										<br>{{ order.billingAddress.postalCode }}
										<br>{{ order.billingAddress.country }}
										<template v-if="order.billingAddress.notes">
											<br><b>{{ $t('notes') }}:</b> {{ order.billingAddress.notes }}
										</template>
									</div>
								</v-col>
							</v-row>
						</v-card>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" class="text-uppercase font-weight-medium">{{ $t('details') }}</v-col>
				</v-row>

				<v-row>
					<v-col cols="12">
						<v-card class="rounded-0 pa-2 pt-6 secondary--text" flat>
							<v-card-text>
								<v-row v-for="(loop_product, idLoopProduct) in nonDeletedProducts" :key="idLoopProduct">
									<v-col cols="12" class="py-0 d-flex justify-space-between align-center">
										<span class="primary--text">{{ $t('item') }} {{ loop_product.sku }}</span>
										<v-btn v-if="isEditable" icon color="red" @click="product_remove(loop_product)"><v-icon>mdi-close</v-icon></v-btn>
									</v-col>
									<v-col cols="12" class="font-weight-bold py-0">{{ loop_product.name }} - {{ loop_product.variation }}</v-col>
									<v-col cols="12" class="py-0">
										<v-simple-table dense>
											<thead>
												<tr>
													<th class="text-center">{{ $t('qty') }}</th>
													<th class="text-center">{{ $t('unitPrice') }}</th>
													<th class="text-center">{{ $t('total') }}</th>
												</tr>
											</thead>
											<tbody style="border-top-color: transparent">
												<tr>
													<td class="text-center">
														<v-text-field v-if="isEditable" v-model="loop_product.qty" outlined dense hide-details class="mx-auto" type="number" :min="1" :step="1" style="width:75px" />
														<span v-else>{{ loop_product.qty }}</span>
													</td>
													<td class="text-center">
														<!--
															NOTE: Don't allow to edit, otherwise we must edit a discount % and not a final price, because what we store in `order_detail` is {regularPrice, discountPercent}
																<v-text-field v-if="isEditable" v-model="loop_product.userPrice" outlined dense hide-details class="mx-auto" type="number" :min="0" :step="0.01" style="width:100px" />
																<span v-else>{{ loop_product.userPrice }}</span>
														-->
														<span>{{ formatCurrency(loop_product.userPrice, "$") }}</span>
													</td>
													<td class="text-center font-weight-bold">{{ formatCurrency(product_getTotal(loop_product), "$") }}</td>
												</tr>
											</tbody>
										</v-simple-table>
									</v-col>
									<v-col v-if="isEditable" cols="10" offset="1" class="pt-6 pb-4"><v-divider class="secondary--text" /></v-col>
									<v-col v-else            cols="10" offset="1" class="pt-2 pb-6"><v-divider class="secondary--text" /></v-col>
								</v-row>
								<v-list-item class="mt-3">
									<v-col cols="8" class="text-right secondary--text pb-0">{{ $t('subTotal') }}</v-col>
									<v-col cols="4" class="text-right secondary--text pb-0">{{ formatCurrency(subTotal, "$") }}</v-col>
								</v-list-item>
								<v-list-item>
									<v-col cols="8" class="text-right secondary--text pt-0">{{ $t('taxes') }}</v-col>
									<v-col cols="4" class="text-right secondary--text pt-0">{{ formatCurrency(taxes, "$") }}</v-col>
								</v-list-item>
								<v-list-item>
									<v-col cols="8" class="text-right secondary--text font-weight-bold">{{ $t('total') }}</v-col>
									<v-col cols="4" class="text-right secondary--text font-weight-bold">{{ formatCurrency(total, "$") }}</v-col>
								</v-list-item>
							</v-card-text>
							<v-card-actions v-if="isEditable">
								<v-btn block color="primary" outlined @click="product_add">{{ $t('addProduct') }}</v-btn>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-row>
				
				<v-row>
					<v-col cols="12" class="text-uppercase font-weight-medium">{{ $t('notes') }}</v-col>
					<v-col cols="12">
						<v-textarea outlined class="rounded-0 secondary--text" background-color="white" color="primary" :disabled="!isEditable" v-model="order.notes" />
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<span class="text-uppercase font-weight-medium text-subtitle-1 mr-2">{{ $t('status') }}:</span>
						<span>{{ statusLabel }}</span>
					</v-col>
				</v-row>
				<v-row v-if="shouldWarnCreditMargin">
					<v-col cols="12">
						<v-alert type="warning">{{ $t('shouldWarnCreditMargin') }}</v-alert>
					</v-col>
				</v-row>
				<v-row v-if="isEditable" justify="space-between">
					<v-col cols="auto"> <v-btn color="red"     class="white--text py-6" @click="cancelOrder"  :disabled="!isEditable || isLoading"                           :loading="isLoading">{{ $t('cancel') }}</v-btn> </v-col>
					<v-col cols="auto"> <v-btn color="primary" class="white--text py-6" @click="approveOrder" :disabled="!isEditable || isLoading || shouldWarnCreditMargin" :loading="isLoading">{{ $t('approve') }}</v-btn> </v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-row v-if="errMessage" class="mt-5" justify="center">
			<v-col class="py-0">
				<v-alert outlined type="error" text>{{ errMessage }}</v-alert>
			</v-col>
		</v-row>
		
		
		<v-dialog :value="showStatusChangedPopup" width="20rem">
			<v-card>
				<v-card-title class="justify-center py-5 pb-0 ma-0">
					<v-icon color="primary" class="kb-icon-check">mdi-check</v-icon>
				</v-card-title>
				<v-card-title class="justify-center pa-0 ma-0 secondary--text text-h4">{{ $t('thanks') }}</v-card-title>
				<v-card-title class="justify-center pa-0 pb-12 ma-0 secondary--text subtitle-1">{{ $t('updated') }}</v-card-title>
			</v-card>
		</v-dialog>
		
				
	</v-container>	
</template>

<script>
	
	import KbApi from "@/classes/KbApi.js";

	export default {
		i18n: {
			messages: {
				fr: {
					order: `Commande`,
					generalInfo: `Informations de base`,
					deliveryAddresses: `Adresses`,
					deliveryAddress: `Livraison`,
					notes: `Notes`,
					billing: `Facturation`,
					details: `Détails de la commande`,
					item: `Item`,
					qty: `Quantité`,
					unitPrice: `Prix unit.`,
					total: `Total`,
					subTotal: `Sous-total`,
					taxes: `Taxes`,
					addProduct: `Ajouter un produit`,
					status: `Statut`,
					cancel: `ANNULER`,
					approve: `APPROUVER`,
					thanks: `Merci!`,
					updated: `Commande mise à jour`,
					statuses: {
						in_submission: `Le client est en train de préparer cette commande`,
						to_approve: `Le client vient de passer la commande`,
						canceled: `Cancelée`,
						approved: `Approuvée`,
					},
					addProductMsgs: {
						prompt: `Veuillez entrer un SKU de produit, ex 010-0000010`,
						notFound: `Le produit n'a pas pu être trouvé`,
					},
					shouldWarnCreditMargin: `La commande ne peut être approuvée puisque la limite de crédit est dépassée. Veuillez aller dans Genius faire le changement, ensuite attendre une heure pour que la synchro s'effectue avec l'application, puis raffraichir cette page`,
				},
				en: {
					order: `Order`,
					generalInfo: `General informations`,
					deliveryAddresses: `Addresses`,
					deliveryAddress: `Delivery`,
					notes: `Notes`,
					billing: `Billing`,
					details: `Order details`,
					item: `Item`,
					qty: `Quantity`,
					unitPrice: `Unit price`,
					total: `Total`,
					subTotal: `Subtotal`,
					taxes: `Taxes`,
					addProduct: `Add a product`,
					status: `Status`,
					cancel: `CANCEL`,
					approve: `APPROVE`,
					thanks: `Thanks!`,
					updated: `Order updated`,
					statuses: {
						in_submission: `Client preparing order`,
						to_approve: `Client submitted order`,
						canceled: `Canceled`,
						approved: `Approved`,
					},
					addProductMsgs: {
						prompt: `Please enter a product SKU, eg. 010-0000010`,
						notFound: `Product not found`,
					},
					shouldWarnCreditMargin: `The order cannot be approved because the credit limit is exceeded. Please go in Genius to make the change, then wait an hour for the synchronization to occur with the application, then refresh this page`,
				},
			}
		},
		data() {
			return {
				pageLoading:true,
				order:{},
				deliveryAddresses:[],
				errMessage: "",
				isLoading:false,
				showStatusChangedPopup: false,
			}
		},
		async mounted()
		{
			await this.loadOrder();
		},
		computed: {
			statuses()
			{
				return [
					{value:"in_submission", text:this.$t('statuses.in_submission')},
					{value:"to_approve",    text:this.$t('statuses.to_approve')},
					{value:"canceled",      text:this.$t('statuses.canceled')},
					{value:"approved",      text:this.$t('statuses.approved')},
				];
			},
			shouldWarnCreditMargin() { return this.order ? !!this.order.user.shouldWarnCreditMargin : false; },
			isEditable() { return this.order.status==="to_approve"; },
			// isEditable() { return false; },
			selectedDeliveryAddress()
			{
				const selectedDeliveryAddress = this.deliveryAddresses.find(loop_deliveryAddress => loop_deliveryAddress.pk===this.order.deliveryAddress.pk);
				return selectedDeliveryAddress ? selectedDeliveryAddress.details : null;
			},
			statusLabel()
			{
				const currentStatus = this.statuses.find(loop_status => loop_status.value===this.order.status);
				return currentStatus.text || "?";
			},
			nonDeletedProducts()
			{
				return this.order.products.filter(loop_product => !loop_product.toDelete);
			},
			subTotal() { return this.isEditable ? this.order.products.reduce((acc,loop_product) => acc+this.product_getTotal(loop_product), 0) : this.order.subTotal; },
			taxes() { return this.isEditable ? this.subTotal*this.order.combinedTaxRate : this.order.taxes; },
			total() { return this.isEditable ? this.subTotal+this.taxes : this.order.total; },
		},
		methods: {
			async loadOrder()
			{
				this.pageLoading = true;
				
				try
				{
					const data = await KbApi.get(`/api/orders/${this.$route.query.id}`, this.$store.state.user);
					
					await this._loadDeliveryAddresses(data.user.pk);
					
					this.order = data;
				}
				catch (error)
				{
					this.errMessage = this.$KbUtils.parseApiError(error);
				}
				
				this.pageLoading = false;
			},
				async _loadDeliveryAddresses(pkUser)
				{
					try
					{
						const data = await KbApi.get(`/api/users/${pkUser}`, this.$store.state.user);
						const deliveryAddresses = [];
						data.user.deliveryAddresses.forEach(loop_deliveryAddress =>
						{
							deliveryAddresses.push({
								pk: loop_deliveryAddress.pk,
								addressName: loop_deliveryAddress.addressName,
								details: loop_deliveryAddress,
							});
						});
						this.deliveryAddresses = deliveryAddresses;
					}
					catch (error)
					{
						this.errMessage = this.$KbUtils.parseApiError(error);
					}
				},
			product_remove(product)
			{
				this.$set(product, "toDelete", true);
			},
			product_getTotal(product)
			{
				return product.toDelete ? 0 : product.qty * product.userPrice;
			},
			async product_add()
			{
				const sku = prompt(this.$t('addProductMsgs.prompt'));
				if (sku)
				{
					try
					{
						const data = await KbApi.get(`/api/orders/${this.$route.query.id}/getSKUInfo/${sku}`, this.$store.state.user);
						const variation = data.variations.find(loop_variation => loop_variation.sku === sku);
						if (!variation) { throw new Error(`SKU "${sku}" wasn't returned`); }
						
						this.order.products.push({
							order_details_pk: null,
							pk: variation.pk,
							name: data.name,
							variation: variation.name,
							sku: variation.sku,
							qty: 1,
							regularPrice: variation.regularPrice,
							userPrice: variation.userPrice,
							discount: null, //No need here
							toDelete: false,
						});
					}
					catch (error)
					{
						alert(this.$t('addProductMsgs.notFound'));
					}
				}
			},
			async cancelOrder()
			{
				this._updateOrder("canceled");
			},
			async approveOrder()
			{
				this._updateOrder("approved");
			},
				async _updateOrder(status)
				{
					this.isLoading = true;
					
					try
					{
						const patchData = {
							deliveryAddress_fk: this.order.deliveryAddress.pk,
							products: this.order.products,
							notes: this.order.notes,
							status,
						};
						const data = await KbApi.patch(`/api/orders/${this.$route.query.id}`, patchData, this.$store.state.user);
					}
					catch (error)
					{
						this.errMessage = this.$KbUtils.parseApiError(error);
					}
					
					//Do this before it turns RO, as we'll revert computed vals to use this.order.x instead of suming from this.order.products
					this.order.subTotal = this.subTotal;
					this.order.taxes    = this.taxes;
					this.order.total    = this.total;
					
					this.order.status = status;
					this.isLoading    = false;
					
					this.showStatusChangedPopup = true;
				},
		},
	};
	
</script>

<style scoped>
	
	.v-data-table >>> th {
		border-bottom: none !important;
	}
	
	.v-divider {
		border-width: 1px;
		border-color: var(--secondary--text);
	}
	
	.kb-icon-check {
		font-size: 12rem;
	}

</style>