<template>
	
	<v-container class="kb-container">
		<v-form v-model="formIsValid" ref="form">
			<v-row class="mt-10">
				<v-col cols="12" class="py-0">
					<v-text-field :label="$t('email')" v-model="email" outlined class="rounded-0" :rules="[rules.required, rules.email]" />
				</v-col>
				<v-col cols="12" class="py-0">
					<v-text-field class="rounded-0" v-model="pwd" @keyup.enter="submit" outlined :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required]" :type="showPwd ? 'text' : 'password'" name="input-10-2" :label="$t('pwd')" @click:append="showPwd = !showPwd" />
				</v-col>
			</v-row>
			<v-row v-if="errMessage" class="mt-5" justify="center">
				<v-alert outlined type="error" text>{{ errMessage }}</v-alert>
			</v-row>
			<v-row>
				<v-col class="text-center mt-3">
					<v-btn color="primary" class="rounded-0 py-7 font-weight-regular" width="100%" :disabled="loginInProgress || !formIsValid" @click="submit" :loading="loginInProgress">{{ $t('logIn') }}</v-btn>
				</v-col>
			</v-row>
		</v-form>
		
		<v-card class="mt-16">
			<v-card-title>Logins tests</v-card-title>
			<v-card-text>
				<v-row justify="space-around">
					<v-col cols="auto"> <v-btn @click="login_test('test1@test.ca','test')">Admin</v-btn> </v-col>
					<v-col cols="auto"> <v-btn @click="login_test('test2@test.ca','test')">Client</v-btn> </v-col>
				</v-row>
			</v-card-text>
		</v-card>
		
	</v-container>

</template>

<script>
	
	import KbApi from "@/classes/KbApi.js";

	export default {
		i18n: {
			messages: {
				fr: {
					email: `Courriel`,
					pwd: `Mot de passe`,
					logIn: `Me connecter`,
					validation: {
						required: `Requis`,
						email: `Courriel invalide`,
					},
					msgs: {
						notYetApproved: `Votre compte n'a pas encore été approuvé, un représentant vous contactera sous peu pour finaliser votre inscription.`,
						isDeactivated: `Votre compte est désactivé, veuillez nous contacter pour plus d'informations.`,
						incorrectEmailPwd: `Votre courriel et/ou votre mot de passe est incorrect.`,
						errorOccured: `Une erreur est survenue, merci de vérifier vos informations puis de réessayer. N'hésitez pas à nous contacter si le problème persiste.`,
					},
				},
				en: {
					email: `Email`,
					pwd: `Password`,
					logIn: `Log in`,
					validation: {
						required: `Required`,
						email: `Invalid email`,
					},
					msgs: {
						notYetApproved: `Your account hasn't yet been approved, a representative will contact you shortly to complete your registration.`,
						isDeactivated: `Your account is deactivated, please contact us for more informations.`,
						incorrectEmailPwd: `Your email and/or password are incorrect.`,
						errorOccured: `An error occured, please validate your informations and rety. Don't hesitate to contact us if the problem persists.`,
					},
				},
			}
		},
		data() {
			return {
				email: "",
				pwd: "",
				errMessage: "",
				loginInProgress: false,
				formIsValid: true,
				showPwd:false,
			}
		},
		computed: {
			rules()
			{
				return {
					required: value => !!value || this.$t('validation.required'),
					email: value => {
						const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
						return pattern.test(value) || this.$t('validation.email')
					},
				};
			},
		},
		methods: {
			async login_test(email, pwd)
			{
				this.email = email;
				this.pwd   = pwd;
				await this.$KbUtils.fakeSleep(0);
				
				this.submit();
			},
			submit() {
				this.errMessage = "";
				this.$refs.form.validate();
				if(this.formIsValid) { this.apiLogin(); }
			},
			async apiLogin()
			{
				this.loginInProgress = true;
				
				const body = {
					email: this.email,
					pwd: this.pwd,
				};

				// console.log(body);
				let data;
				try
				{
					data = await KbApi.post('/api/account/login', body, this.$store.state.user);
				}
				catch (error)
				{
					if (error.response?.status === 401)
					{
						if(error.response.data.startsWith("User awaiting approval")) { this.errMessage = this.$t('msgs.notYetApproved'); }
						else if(error.response.data.startsWith("User disabled")) { this.errMessage = this.$t('msgs.isDeactivated'); }
						else { this.errMessage = this.$t('msgs.incorrectEmailPwd'); }
					} 
					
					if (this.errMessage === "") { this.errMessage = `${this.$t('msgs.errorOccured')} (${error.response.status} )`; }
					
					this.loginInProgress = false;
				}
				
				this.loginInProgress = false;
				
				if (this.errMessage === "")
				{
					this.$store.dispatch("user_logIn", data);
				}
			},
		},
	};
	
</script>

<style scoped>
	
	.kb-container {
		max-width: 400px !important;
	}

	.v-input >>> .v-input__slot {
		background: #fff;
	}

	.v-text-field--outlined >>> fieldset {
	  border-color: var(--v-primary-base);
	}

	.v-input >>> input {
	  color: black;
	}

	.v-input >>> label {
	  color: var(--v-primary-base);
	}

</style>