import Vue from "vue";
import VueI18n from "vue-i18n";
import oPreloadedLocJSON_fr from "@/loc/fr"; //NOTE: This syntax works for both .js and .json, as long as loader is webpack...



Vue.use(VueI18n);

/*
IMPORTANT:
	Components' this.$i18n don't point to root i18n obj (querySelector('#kb-main').__vue__.$i18n),
	because each component have their own .messages prop, auto calculating fallbacks and stuff
*/


const oRootI18n = new VueI18n({
	fallbackLocale: "fr",
	locale: "fr", //Current lang
	silentFallbackWarn: false, //WARNING: If components declare i18n props, it'll generate tons of warnings for all loc that can't be found in component's i18n {} and require lookup in root .json files
	messages: {
		fr: oPreloadedLocJSON_fr,
		en: null,
	},
    preserveDirectiveContent: true, //Check https://kazupon.github.io/vue-i18n/guide/directive.html#use-with-transitions
});



//NOTE: We do like this and not against !!this.messages[sLang], because when components declare i18n nodes, their this.$i18n prop becomes a diff one, and so .messages prop isn't the same too
oRootI18n._kb_lang_loadedLangs = [oRootI18n.locale];

VueI18n.prototype.kb_lang_getSupportedList = function(sLang) { return Object.keys(oRootI18n.messages);          };
VueI18n.prototype.kb_lang_isSupported      = function(sLang) { return oRootI18n.messages.hasOwnProperty(sLang); };

VueI18n.prototype.kb_lang_getLoadedList = function(sLang) { return oRootI18n._kb_lang_loadedLangs;                 };
VueI18n.prototype.kb_lang_isLoaded      = function(sLang) { return oRootI18n._kb_lang_loadedLangs.includes(sLang); };

//NOTE: Loads, but doesn't switch. Do .locale=...
VueI18n.prototype.kb_lang_loadAsync = async function(sLang)
{
	if (!this.kb_lang_isSupported(sLang)) { return Promise.reject(new Error(`Lang "${sLang}" not supported`)); }
	
	if (this.kb_lang_isLoaded(sLang)) { return Promise.resolve(); }
	
	return import(/* webpackChunkName: "lang-[request]" */ `@/loc/${sLang}`).then(oJSON => //Check https://webpack.js.org/api/module-methods/#magic-comments for webpackChunkName info + if using eslint, must disable rules "indent" & "template-curly-spacing"
	{
		oRootI18n._kb_lang_loadedLangs.push(sLang);
		this.setLocaleMessage(sLang, oJSON);
		return Promise.resolve();
	});
};
VueI18n.prototype.kb_lang_switchAsync = async function(sLang)
{
	await this.kb_lang_loadAsync(sLang); //If it throws exception (rejects), since this method is also async, it'll generate a Promise.reject()
	
	oRootI18n.locale = sLang;
	
	return Promise.resolve();
};



export default oRootI18n;
