<template>

	<v-container v-if="product">

		<v-row>
			<v-col>
				<v-card class="mx-auto rounded-0 pa-6 secondary--text" flat>
					
					<!-- When in mobile -->
					<template v-if="$vuetify.breakpoint.smAndDown">
						
						<v-row v-if="displayFavIcon">
							<v-col cols="10" sm="11"><h2>{{ product.name }}</h2></v-col>
							<v-col cols="2" sm="1"><kb-product-fav-icon v-model="product.isFavorite" :id-product="product.pk" /></v-col>
						</v-row>
						<v-row v-else>
							<v-col cols="12"><h2>{{ product.name }}</h2></v-col>
						</v-row>
						
						<v-row> <v-col cols="12" v-html="product.desc" /> </v-row>
						<v-row v-if="hasMedias">
							<v-col cols="12"> <kb-product-medias v-if="product" :images="product.images" :video-url="product.videoUrl" /> </v-col>
						</v-row>
					</template>
						
						
					<!-- When in desktop -->
					<template v-else>
						<v-row>
							<v-col :cols="hasMedias ? 6 : 12">
								
								<v-row v-if="hasMedias || !displayFavIcon">
									<v-col cols="12"><h2>{{ product.name }}</h2></v-col>
								</v-row>
								<v-row v-else>
									<v-col cols="11"><h2>{{ product.name }}</h2></v-col>
									<v-col cols="1"><kb-product-fav-icon v-model="product.isFavorite" :id-product="product.pk" /></v-col>
								</v-row>
								
								<v-row> <v-col cols="12" v-html="product.desc" /> </v-row>
							</v-col>
							<v-col v-if="hasMedias" cols="6">
								<v-row v-if="displayFavIcon" justify="end"> <v-col cols="auto"><kb-product-fav-icon v-model="product.isFavorite" :id-product="product.pk" /></v-col> </v-row>
								<v-row> <v-col cols="12"> <kb-product-medias v-if="product" :images="product.images" :video-url="product.videoUrl" /> </v-col> </v-row>
							</v-col>
						</v-row>
					</template>
					
					<kb-product-variation-selector v-if="$store.state.user.isLoggedIn" :variations="product.variations" />
					<v-simple-table v-else>
						<thead>
							<tr>
								<th>{{ $t('dimensions') }}</th>
								<th style="min-width:150px">#</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(loop_variation,loop_idx) in product.variations" :key="loop_idx">
								<td>{{ loop_variation.name }}</td>
								<td class="primary--text">{{ loop_variation.sku }}</td>
							</tr>
						</tbody>
					</v-simple-table>
					
				</v-card>
			</v-col>
		</v-row>
		
		<v-row v-if="errMessage" class="mt-5" justify="center">
			<v-col class="py-0">
				<v-alert outlined type="error" text>{{ errMessage }}</v-alert>
			</v-col>
		</v-row>
		
		<kb-product-related-list :related-products="product.relatedProducts" />
		
	</v-container>
</template>

<script>

	import KbApi from "@/classes/KbApi.js";
	
	export default {
		i18n: {
			messages: {
				fr: {
					dimensions: `Dimensions`,
				},
				en: {
					dimensions: `Dimensions`,
				},
			}
		},
		components: {
			KbProductMedias: () => import("@/components/general/KbProductMedias.vue"),
			KbProductRelatedList: () => import("@/components/general/KbProductRelatedList.vue"),
			KbProductVariationSelector: () => import("@/components/general/KbProductVariationSelector.vue"),
			KbProductFavIcon: () => import("@/components/general/KbProductFavIcon.vue"),
		},
		data() {
			return {
				product: null,
				errMessage: "",
			}
		},
		async mounted()
		{
			try
			{
				this.product = await KbApi.get(`/api/products/${this.$route.query.id}`, this.$store.state.user);
			}
			catch (error)
			{
				this.errMessage = this.$KbUtils.parseApiError(error);
			}
		},
		computed: {
			hasMedias() { return this.product && (this.product.images.length>0 || this.product.videoUrl); },
			displayFavIcon() { return this.$store.state.user.isLoggedIn && !this.$store.state.user.isAdmin; },
		},
	};

</script>