<template>
	
	<v-container class="fill-height">
		<v-row>
			<v-col class="text-center">
				<v-btn color="primary" to="/orders">{{ $t('myOrders') }}</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="mt-10">
				<v-alert type="info" text width="auto">{{ $t('contactUs') }}</v-alert>
			</v-col>
		</v-row>

	</v-container>
	
</template>
<script>
	
	export default {
		i18n: {
			messages: {
				fr: {
					myOrders: `Voir mes commandes`,
					contactUs: `Veuillez contacter votre représentant si vous souhaitez mettre à jours vos informations.`,
				},
				en: {
					myOrders: `My orders`,
					contactUs: `Please contact your representative if you want to update your informations.`,
				},
			}
		},
		data() {
			return {
			}
		},
	};
	
</script>
