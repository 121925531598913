<template>
	<kb-orders mode="singleUser" />
</template>
<script>

	export default {
		data() {
			return {
			}
		},
		methods: {
		},
		components: {
			KbOrders: () => import("@/components/modules/KbOrders.vue")
		}

	};
	
</script>

<style scoped>
</style>