<template>
	
	<v-container class="fill-height secondary--text">

		<v-row>
			<v-col lg="6" offset-lg="3" md="8" offset-md="2" sm="8" offset-sm="2">{{ $t('search') }}</v-col>
		</v-row>
		<v-row>
			<v-col lg="6" offset-lg="3" md="8" offset-md="2" sm="8" offset-sm="2">
				<v-text-field outlined v-model="search" append-icon="mdi-magnify" @click:append="goToPage()" class="rounded-0" background-color="white" @change="goToPage()" :placeholder="$t('searchPlaceholder')" />
			</v-col>
		</v-row>


		<v-row v-if="pageLoading" justify="center">
			<v-progress-circular :size="200" color="primary" indeterminate class="my-16" />
		</v-row>
		<v-row v-else>
			<v-col v-for="(loop_favorite, idFavorite) in favorites" :key="idFavorite" lg="6" md="6" sm="12">
				<v-card class="mx-auto rounded-0 pa-2 pb-5 secondary--text" flat>
					<v-list-item>
						<v-list-item-avatar tile size="100" color="accent">
							<v-img v-if="loop_favorite.image" max-height="100" max-width="100" :src="loop_favorite.image" />
							<v-icon v-else x-large>mdi-image-broken-variant</v-icon>
						</v-list-item-avatar>
						<v-list-item-content>
							<div class="text-right">
								<kb-product-fav-icon v-model="loop_favorite.isFavorite" :id-product="loop_favorite.pk" :id-user="idUser" />
							</div>
							<v-spacer />
							<v-list-item-title class="text-subtitle-1 font-weight-bold kb-two-lines">{{ loop_favorite.name }}</v-list-item-title>
							<v-spacer />
							<v-row class="py-0">
								<v-col cols="12" class="primary--text">{{ $t('item') }} {{ $KbUtils.ellipsis(loop_favorite.skus,50) }}</v-col>
							</v-row>
						</v-list-item-content>

					</v-list-item>
				</v-card>
			</v-col>
		</v-row>
		<v-row v-if="errMessage" class="mt-8" justify="center">
			<v-alert outlined type="error" text>{{ errMessage }}</v-alert>
		</v-row>
		
		<v-row justify="center">
			<v-pagination v-model="paging.currentPage" :length="paging.pageCount" :total-visible="7" @input="goToPage(paging.currentPage)" />
		</v-row>		
		
	</v-container>
	
</template>
<script>
	
	import KbApi from "@/classes/KbApi.js";

	export default {
		i18n: {
			messages: {
				fr: {
					search: `RECHERCHE`,
					item: `Item`,
					searchPlaceholder: `# Item, Nom du produit`,
				},
				en: {
					search: `SEARCH`,
					item: `Item`,
					searchPlaceholder: `Item #, product number`,
				},
			}
		},
		components: {
			KbProductFavIcon: () => import("@/components/general/KbProductFavIcon.vue"),
		},
		data() {
			return {
				pageLoading:true,
				favorites:[],
				paging:{},
				idUser: null,
				lastQsa: "",
				search:"",
				errMessage:"",
			}
		},
		mounted()
		{
			let pageIndexFromQsa = this.$route.query.pageIndex;
			this.paging.pageIndex = (typeof pageIndexFromQsa !== 'undefined') && (pageIndexFromQsa = parseInt(pageIndexFromQsa, 10)) > 1 ? pageIndexFromQsa : 1;
			this.idUser = parseInt(this.$route.query.id);
			this.loadFavorites();
		},
		methods: {
			goToPage(page = 1) {
				this.paging.pageIndex = page;
				this.errMessage = "";
				this.loadFavorites();
			},
			async loadFavorites()
			{
				this.pageLoading = true;
				const qsa = {
					pageSize: 6
				};

				if(this.paging.pageIndex > 1) {
					qsa.pageIndex = this.paging.pageIndex;
				}

				if(this.search) {
					qsa.search = this.search;
				}

				try {
					if(JSON.stringify(qsa) !== JSON.stringify(this.lastQsa)) {
						const data = await KbApi.get(`/api/users/${this.idUser}/favorites`, this.$store.state.user, qsa);
						this.favorites = data.items;
						this.paging = data.paging;
						this.lastQsa = qsa;
						window.scrollTo(0, 0);
					}
				} catch (error) {
					this.errMessage = this.$KbUtils.parseApiError(error);
				} finally {
					this.pageLoading = false;
				}
			},
		},
	};
	
</script>

<style scoped>
	
	.v-list-item__title {
		height: 50px;
	}

	.v-list-item__subtitle {
		height: 70px;
		line-height: 1.5rem;
	}
	
</style>
