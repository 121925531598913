<template>
	<kb-user-form :confirm-label="$t('label')" />
</template>
<script>

	export default {
		i18n: {
			messages: {
				fr: {
					label: `SAUVEGARDER`,
				},
				en: {
					label: `SAVE`,
				},
			}
		},
		data() {
			return {
			}
		},
		methods: {
		},
		components: {
			KbUserForm: () => import("@/components/modules/KbUserForm.vue")
		}

	};
	
</script>

<style scoped>
	
	.kb-container {
		max-width: 800px !important;
	}
	
</style>