<template>

	<v-container class="kb-container py-0 text--secondary">
		<v-row>
			<v-col cols="12" md="6" offset-md="3" >
				<v-row v-if="pageLoading" justify="center">
					<v-progress-circular :size="200" color="primary" indeterminate class="my-16" />
				</v-row>
				<template v-else>
					<v-row v-if="successSaved" class="my-10" justify="center">
						<v-alert outlined type="success" text>{{ $t('msgSent') }}</v-alert>
					</v-row>
					<v-form v-else v-model="formIsValid" ref="form">
						<v-row>
							<v-col cols="12" class="pb-0 font-weight-medium">{{ $t('chooseRecipients') }}</v-col>
							<v-col cols="12">
								<v-select v-model="territoriesSelected" :items="territories" multiple outlined :placeholder="$t('allTerritories')" class="rounded-0" :messages="[selectedRecipientCountMsg]" />
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" class="pb-0 font-weight-medium">{{ $t('subject') }}</v-col>
							<v-col cols="12">
								<v-text-field outlined class="rounded-0 secondary--text" background-color="white" color="primary" v-model="subject" :rules="[rules.required]" />
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" class="pb-0 font-weight-medium">{{ $t('body') }}</v-col>
							<v-col cols="12">
								<v-textarea outlined class="rounded-0 secondary--text" background-color="white" color="primary" v-model="message" :rules="[rules.required]" />
							</v-col>
						</v-row>
						<v-row v-if="errMessage" class="mt-5" justify="center">
							<v-col class="py-0">
								<v-alert outlined type="error" text>{{ errMessage }}</v-alert>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" sm="6" offset-sm="6" class="text-center mt-2">
								<v-btn color="primary" class="py-7 font-weight-regular text-none" width="100%" @click="submit" :disabled="nbRecipients() < 1" :loading="saveInProgress">
									<v-icon class="mr-2">mdi-email-send</v-icon>
									{{ $t('send') }}
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
				</template>
			</v-col>
		</v-row>
	</v-container>



</template>

<script>
	
	import KbApi from "@/classes/KbApi.js";
	import KbEmail from "@/classes/KbEmail.js";

	export default {
		i18n: {
			messages: {
				fr: {
					msgSent: `Message envoyé avec succès!`,
					subject: `OBJET`,
					body: `MESSAGE`,
					send: `ENVOYER`,
					allTerritories: `Tous les territoires`,
					chooseRecipients: `CHOISIR LE(S) TERRITOIRE(S)`,
					selectedRecipients: `Destinataires sélectionnés:`,
				},
				en: {
					msgSent: `Message sent !`,
					subject: `SUBJECT`,
					body: `MESSAGE`,
					send: `SEND`,
					allTerritories: `All territories`,
					chooseRecipients: `CHOOSE TERRITORIES`,
					selectedRecipients: `Selected recipients:`,
				},
			}
		},
		data() {
			const rules = {
				required: value => !!value || 'Requis',
			};

			return {
				territories:[],
				territoriesSelected:[],
				subject:"",
				message:"",

				emailsByTerritory:[],
				successSaved: false,
				errMessage: false,
				formIsValid:false,
				rules,
				saveInProgress:false,
                pageLoading: true,
			};
		},
		computed: {
			selectedRecipientCountMsg() { return `${this.$t('selectedRecipients')} ${this.nbRecipients()}`; },
		},
		methods: {
			async loadTerritories()
			{
                try {
                    const data = await KbApi.get('/api/admin/getTerritories', this.$store.state.user);
                    data.forEach((territory) => {
                        this.territories.push({text: territory.name, value: territory.pk});
                        this.emailsByTerritory[territory.pk] = territory.emails;
                    });
                } catch (error) {
                    this.errMessage = `Une erreur ${error.response?.status} est survenue lors du chargement des territoires, merci de recharger la page.`;
                } finally {
                    this.pageLoading = false;
				}
			},
			getAllRecipientsSelected() {
				return this.emailsByTerritory.filter((value, key)=> value && (!this.territoriesSelected.length||this.territoriesSelected.includes(key))).flat();
			},
			nbRecipients() {
				return this.getAllRecipientsSelected().length;
			},
			submit() {
				this.errMessage = "";
				this.$refs.form.validate();

				if(this.formIsValid) {
					this.saveInProgress = true;
					this.sendMessage();
				}
			},
			async sendMessage()
			{
				const recipients = this.getAllRecipientsSelected();
				
				const response = await KbEmail.sendToAll(this.subject, this.message, recipients, this.$store.state.user);
				if (response)
				{
					this.saveInProgress = false;
					this.errMessage = response
				}

				this.successSaved = this.errMessage === "";
			}
		},
		mounted() {
			this.loadTerritories();
		},
	};
	
</script>

<style scoped>
.v-text-field >>> .v-input__slot {
	background: #fff;
}

.v-text-field--outlined >>> fieldset {
  border-color: var(--v-primary-base);
}

.v-text-field >>> input {
  color: black;
}

.v-text-field >>> label {
  color: var(--v-primary-base);
}

.v-input--reverse >>> .v-input__slot {
  background: none;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-right: 0;
  margin-left: 8px;
}

.v-input--reverse >>> .v-input__slot label {
  justify-content: flex-end;
  margin-right: 15px;
}
</style>