import axios from "axios";
import { isBoolean } from "lodash";

export default class KbApi
{
	static lang = "fr";
	
	static async get(path, user=null, params = {}) {
		// axios
		// 	.get(`https://t4s-dev.keybook.com/api/products?${qsa.join('&')}`)
		// 	.then(response => {
		// 		this.products = response.data.items;
		// 		this.paging = response.data.paging;
		// 	}
		// );

		const config = { headers: {}};
		const qsa = [];
		
		let filterValue;
		for (const [key, value] of Object.entries(params)) {
			if(value !== null) {
				if(Array.isArray(value)) {
					if( value.length > 0) { qsa.push(`${key}=${value.join(',')}`); }
				}
				else if(isBoolean(value) && value) {
					qsa.push(`${key}`);
				}
				else {
					filterValue = value.toString().replaceAll('&', '%20');
					qsa.push(`${key}=${filterValue}`);
				}
			}
		}
		
		qsa.push(`loc=${KbApi.lang}`);

		if(user && user.isLoggedIn && user.token_public) {
			config.headers.Authorization = `Bearer ${user.token_public}`;
		}

		// console.log("CALL API GET " + `${path}?${qsa.join('&')}`);
		const res = await axios.get(`${path}?${qsa.join('&')}`, config).catch(err => {
			throw err;
		});
		// console.log(res.data);
		return res.data;
	}

	static async post(path, body={}, user=null) {
		// await new Promise(resolve => setTimeout(resolve, 1000));

		const headers = {'content-type': 'application/json' };
		if(user && user.isLoggedIn && user.token_public) {
			headers.Authorization = `Bearer ${user.token_public}`;
		}
		
		path += `?loc=${KbApi.lang}`;
		
		// console.log("CALL API POST " + `${path}`);
		// console.log(body);
		// console.log(headers);
		const res = await axios.post(path, JSON.stringify(body), { headers: headers}).catch(err => {
			throw err;
		});
		return res.data;
	}

	static async patch(path, body={}, user=null) {
		// await new Promise(resolve => setTimeout(resolve, 1000));

		const headers = {'content-type': 'application/json' };
		if(user && user.isLoggedIn && user.token_public) {
			headers.Authorization = `Bearer ${user.token_public}`;
		}
		
		path += `?loc=${KbApi.lang}`;
		
		// console.log("CALL API PATCH " + `${path}`);
		// console.log(body);
		// console.log(headers);
		const res = await axios.patch(path, JSON.stringify(body), { headers: headers}).catch(err => {
			throw err;
		});
		return res.data;
	}
}