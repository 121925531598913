<template>
	
	<v-container class="kb-container">

		<v-row v-if="pageLoading" justify="center" align="center">
			<v-col class="mt-16 text-center"> <v-progress-circular :size="50" color="primary" indeterminate /> </v-col>
		</v-row>
		<v-row v-if="pageLoading" justify="center" align="center">
			<v-col class="text-center">{{ $t('loginOut') }}</v-col>
		</v-row>

		<v-row v-if="errMessage" class="mt-5" justify="center">
			<v-alert outlined type="error" text>{{ errMessage }}</v-alert>
		</v-row>

	</v-container>

</template>
<script>
	
	import KbApi from "@/classes/KbApi.js";

	export default {
		i18n: {
			messages: {
				fr: {
					loginOut: `Déconnexion en cours...`,
				},
				en: {
					loginOut: `Login out...`,
				},
			}
		},
		data() {
			return {
				pageLoading: true,
				errMessage:"",
			}
		},
		async mounted()
		{
			if(!this.$store.state.user.isLoggedIn)
			{
				this.$router.push({path:"/login"});
			}
			else
			{
				this.errMessage = "";
				let data;
				try
				{
					data = await KbApi.post('/api/account/logout', null, this.$store.state.user);
				}
				catch (error)
				{
					this.errMessage = this.$KbUtils.parseApiError(error);
				}
				
				this.pageLoading = false;

				if (this.errMessage === "")
				{
					if (data)
					{
						this.$store.dispatch("user_logIn", data);
					}
					else
					{
						this.$store.dispatch("user_logOut");
					}
				}
			}
		}
	};
	
</script>

<style scoped>
	
	.kb-container {
		max-width: 400px !important;
	}

	.v-input >>> .v-input__slot {
		background: #fff;
	}

	.v-text-field--outlined >>> fieldset {
	  border-color: var(--v-primary-base);
	}

	.v-input >>> input {
	  color: black;
	}

	.v-input >>> label {
	  color: var(--v-primary-base);
	}
	
</style>