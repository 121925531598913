<template>
	
	<v-container class="kb-container">
		<v-row v-if="pageLoading" justify="center">
			<v-progress-circular :size="200" color="primary" indeterminate class="my-16" />
		</v-row>
		<v-row v-else-if="errMessage" class="mt-5" justify="center">
			<v-alert outlined type="error" text>{{ errMessage }}</v-alert>
		</v-row>
	</v-container>

</template>
<script>
	
	import KbApi from "@/classes/KbApi.js";

	export default {
		i18n: {
			messages: {
				fr: {
					notYetApproved: `Impossible de se connecter car l'utilisateur n'est pas encore approuvé`,
					isDeactivated: `Impossible de se connecter car l'utilisateur est désactivé`,
				},
				en: {
					notYetApproved: `Can't log in as this user, because it's not approved yet`,
					isDeactivated: `Can't log in as this user, because it's deactivated`,
				},
			}
		},
		data() {
			return {
				errMessage: "",
				pageLoading: true,
			}
		},
		async mounted()
		{
			this.errMessage = "";
			let data;
			try
			{
				data = await KbApi.post(`/api/admin/sudo-start/${this.$route.query.id}`, null, this.$store.state.user);
			}
			catch (error)
			{
				if (error.response?.status === 401)
				{
					if (error.response.data.startsWith("User awaiting approval")) { this.errMessage = this.$t('notYetApproved'); }
					else if (error.response.data.startsWith("User disabled"))     { this.errMessage = this.$t('isDeactivated');  }
				}
				
				if (this.errMessage === "") { this.errMessage = this.$KbUtils.parseApiError(error); }
			}
			
			this.pageLoading = false;
			if (this.errMessage === "")
			{
				this.$store.dispatch("user_logIn", data);
			}
		},
	};
	
</script>

<style scoped>
	
	.kb-container {
		max-width: 400px !important;
	}

	.v-input >>> .v-input__slot {
		background: #fff;
	}

	.v-text-field--outlined >>> fieldset {
	  border-color: var(--v-primary-base);
	}

	.v-input >>> input {
	  color: black;
	}

	.v-input >>> label {
	  color: var(--v-primary-base);
	}
	
</style>