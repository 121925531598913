import Vue from "vue";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

const requireComponent = require.context("@/components/base", true, /\.vue$/);

for (const loop_file of requireComponent.keys())
{
	const loop_componentConfig = requireComponent(loop_file);
	const loop_name = loop_file.replace(/index.js|^\.\/|\.\w+$/g, "");
	const componentName = upperFirst(camelCase(loop_name));
	
	Vue.component(`Base${componentName}`, loop_componentConfig.default || loop_componentConfig);
}
