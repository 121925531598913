<template>
	
	<v-container class="fill-height secondary--text">
		<v-row v-if="pageLoading" justify="center">
			<v-progress-circular :size="200" color="primary" indeterminate class="my-16" />
		</v-row>
		<v-row v-else>
			<v-col lg="6" offset-lg="3" md="8" offset-md="2" sm="8" offset-sm="2">

				<v-row>
					<v-col class="text-center">
						<h1 class="text-center py-6 font-weight-regular secondary--text">{{ $t('order') }} #{{ order.pk }}</h1>
						{{ order.creation_date }}
					</v-col>
					<v-col cols="12" />
				</v-row>
				
				<v-row>
					<v-col cols="12" class="text-uppercase font-weight-medium">{{ $t('generalInfo') }}</v-col>
					<v-col cols="12">
						<v-card class="rounded-0 pa-5 secondary--text" flat>
							{{ order.user.companyName }}
							<br>{{ order.user.contactName }}
							<br>{{ order.user.email }}
							<br>{{ order.user.phone }}
						</v-card>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" class="text-uppercase font-weight-medium">{{ $t('deliveryAddresses') }}</v-col>
					<v-col cols="12">

					<v-card class="rounded-0 pa-5 secondary--text" flat>
						<v-row>
							<v-col cols="12" sm="6">
								<v-card-title class="pa-0">{{ $t('deliveryAddress') }}</v-card-title>
								{{ order.deliveryAddress.address }}
								<br>{{ order.deliveryAddress.city }}
								<br>{{ order.deliveryAddress.state }}
								<br>{{ order.deliveryAddress.postalCode }}
								<br>{{ order.deliveryAddress.country }}
								<template v-if="order.deliveryAddress.notes">
									<br><b>{{ $t('notes') }}:</b> {{ order.deliveryAddress.notes }}
								</template>
							</v-col>
							<v-col cols="12" sm="6">
								<v-card-title class="pa-0">{{ $t('billing') }}</v-card-title>
								{{ order.billingAddress.address }}
								<br>{{ order.billingAddress.city }}
								<br>{{ order.billingAddress.state }}
								<br>{{ order.billingAddress.postalCode }}
								<br>{{ order.billingAddress.country }}
								<template v-if="order.billingAddress.notes">
									<br><b>{{ $t('notes') }}:</b> {{ order.billingAddress.notes }}
								</template>
							</v-col>
						</v-row>
					</v-card>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" class="text-uppercase font-weight-medium">{{ $t('details') }}</v-col>
				</v-row>

				<v-row>
					<v-col cols="12">
						<v-card class="rounded-0 pa-2 pt-6 text-center secondary--text" flat>
							<v-row v-for="(loop_product, idLoopProduct) in order.products" :key="idLoopProduct">
								<v-col cols="12" class="primary--text pb-0">{{ $t('item') }} {{ loop_product.sku }}</v-col>
								<v-col cols="12" class="font-weight-bold pt-0">{{ loop_product.name }} - {{ loop_product.variation }}</v-col>
								<v-col cols="4">{{ $t('qty') }}<br>{{ loop_product.qty }}</v-col>
								<v-col cols="4" class="kb-unit-price">
									{{ $t('unitPrice') }}<br>
									
									<template v-if="product_isDiscounted(loop_product)">
										<span class="text-decoration-line-through mr-2">{{ formatCurrency(loop_product.regularPrice,"$") }}</span>
										<span class="red--text">{{ formatCurrency(loop_product.userPrice,"$") }}</span>
									</template>
									<template v-else>
										<span>{{ formatCurrency(loop_product.regularPrice,"$") }}</span>
									</template>
									
								</v-col>
								<v-col cols="4">{{ $t('total') }}<br><b>{{ (loop_product.qty*loop_product.userPrice).toFixed(2) }}$</b></v-col>
								<v-col cols="8" offset="2"><v-divider class="secondary--text" /></v-col>
							</v-row>

							<v-list-item class="mt-3">
								<v-col cols="8" class="text-right secondary--text pb-0">{{ $t('subTotal') }}</v-col>
								<v-col cols="4" class="text-right secondary--text pb-0">{{ order.subTotal }} $</v-col>
							</v-list-item>
							<v-list-item>
								<v-col cols="8" class="text-right secondary--text pt-0">{{ $t('taxes') }}</v-col>
								<v-col cols="4" class="text-right secondary--text pt-0">{{ order.taxes }} $</v-col>
							</v-list-item>
							<v-list-item>
								<v-col cols="8" class="text-right secondary--text font-weight-bold">{{ $t('total') }}</v-col>
								<v-col cols="4" class="text-right secondary--text font-weight-bold">{{ order.total }} $</v-col>
							</v-list-item>

						</v-card>
					</v-col>
				</v-row>

				<v-row>
					<v-col cols="12" class="text-uppercase font-weight-medium">{{ $t('notes') }}</v-col>
					<v-textarea outlined class="rounded-0 mt-2 px-5 secondary--text" background-color="#d8d8d8" color="primary" :value="order.notes" disabled />
				</v-row>

			</v-col>
		</v-row>
		<v-row v-if="errMessage" class="mt-5" justify="center">
			<v-col class="py-0">
				<v-alert outlined type="error" text>{{ errMessage }}</v-alert>
			</v-col>
		</v-row>
	</v-container>
	
</template>

<script>
	
	import KbApi from "@/classes/KbApi.js";

	export default {
		i18n: {
			messages: {
				fr: {
					order: `Commande`,
					generalInfo: `Informations de base`,
					deliveryAddresses: `Adresses`,
					deliveryAddress: `Livraison`,
					notes: `Notes`,
					billing: `Facturation`,
					details: `Détails de la commande`,
					item: `Item`,
					qty: `Quantité`,
					unitPrice: `Prix unit.`,
					total: `Total`,
					subTotal: `Sous-total`,
					taxes: `Taxes`,
				},
				en: {
					order: `Order`,
					generalInfo: `General informations`,
					deliveryAddresses: `Addresses`,
					deliveryAddress: `Delivery`,
					notes: `Notes`,
					billing: `Billing`,
					details: `Order details`,
					item: `Item`,
					qty: `Quantity`,
					unitPrice: `Unit price`,
					total: `Total`,
					subTotal: `SubTotal`,
					taxes: `Taxes`,
				},
			}
		},
		data() {
			return {
				pageLoading:true,
				order:{},
				errMessage: "",
			}
		},
		async mounted()
		{
			this.pageLoading = true;

			try
			{
				const data = await KbApi.get(`/api/orders/${this.$route.query.id}`, this.$store.state.user);
				this.order = data;
			}
			catch (error)
			{
				this.errMessage = this.$KbUtils.parseApiError(error);
			}
			
			this.pageLoading = false;
		},
		methods: {
			product_isDiscounted(product) { return product.regularPrice!==product.userPrice; },
		},
	};
	
</script>

<style scoped>
	
	.v-divider {
		border-width: 1px;
		border-color: var(--secondary--text);
	}

	.kb-unit-price {
		position: relative;
	}

	.kb-unit-price::before, .kb-unit-price::after {
		content: "";
		display: inline-block;
		position:absolute;
		width: 2px;
		height: 70%;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		background: #EAEAEA;

	}

	.kb-unit-price::after {
		left: initial;
		right: 0;
	}
	
</style>