<template>
	<kb-user-form class="mt-10" :confirm-label="$t('label')" />
</template>
<script>

	export default {
		i18n: {
			messages: {
				fr: {
					label: `Envoyer la demande`,
				},
				en: {
					label: `Send request`,
				},
			}
		},
		data()
		{
			return { };
		},
		components: {
			KbUserForm: () => import("@/components/modules/KbUserForm.vue")
		}

	};
	
</script>

<style scoped>
	
	.kb-container {
		max-width: 800px !important;
	}
	
</style>