<template>
	<v-fade-transition mode="out-in">
		
		<router-view />
		
	</v-fade-transition>
</template>

<script>
	export default {
		name: "App"
	};
</script>

<style scoped>
a {
	text-decoration:none;
}
</style>

<style>

.kb-three-lines
{
    white-space: initial;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.kb-two-lines
{
    white-space: initial;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.kb-one-line
{
    white-space: initial;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

</style>