
export default class KbCookie
{
    _expDays = 7;

    _isLoggedIn;
    _isAdmin;
    _lang;
    _displayName;
    _token_public;
    _token_private;
    _nbProductCart;

    get isLoggedIn() { return this._isLoggedIn; }
    
    constructor(data = null) {
        if(data === null) {
            this._isLoggedIn = KbCookie.getCookie("isLoggedIn");
            this._isAdmin = KbCookie.getCookie("isAdmin");
            this._lang = KbCookie.getCookie("lang");
            this._displayName = KbCookie.getCookie("displayName");
            this._token_public = KbCookie.getCookie("token_public");
            this._token_private = KbCookie.getCookie("token_private");
            this._nbProductCart = KbCookie.getCookie("nbProductCart");
        } else {
            this._isLoggedIn = data.isLoggedIn;
            this._isAdmin = data.isAdmin;
            this._lang = data.lang;
            this._displayName = data.displayName;
            this._token_public = data.token_public;
            this._token_private = data.token_private;
            this._nbProductCart = data.nbProductCart;
        }
    }

    save() {
        const expireDate = new Date();
        expireDate.setTime(expireDate.getTime() + (this._expDays*24*60*60*1000));
        for (const [key, value] of Object.entries(this.exportToObject())) {
            document.cookie = `${key}=${value};expires=${expireDate.toUTCString()};path=/`;
        }
    }

    exportToObject() {
        return {
            isLoggedIn: this._isLoggedIn,
            isAdmin: this._isAdmin,
            lang: this._lang,
            displayName: this._displayName,
            token_public: this._token_public,
            token_private: this._token_private,
            nbProductCart: this._nbProductCart,
        }
    }

    static getCookie(displayName) {
        const key = `${displayName}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(key) === 0) {
            let value = c.substring(key.length, c.length);
            if(value === "true") {
                value = true;
            } else if( value === "false") {
                value = false;
            }

            return value;
          }
        }
        return null;
    }

    delete() {
        for (const [key, value] of Object.entries(this.exportToObject())) {
            document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        }
    }
}