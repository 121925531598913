import Vue from "vue";
import WebFontLoader from "webfontloader";

import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad);

// async load fonts
WebFontLoader.load({
	google: {
		families: ["Roboto:100,300,400,500,700,900&display=swap"]
	}
});


import Vuetify, {VBtn, VTextField} from "vuetify/lib";
import { Ripple } from "vuetify/lib/directives";
import colors from "vuetify/lib/util/colors"; //NOTE: Importing that can increase app size / prevent treeshaking
import lang_fr from "vuetify/es5/locale/fr";
import lang_en from "vuetify/es5/locale/en";
Vue.use(Vuetify, {
	components: {VBtn} // Global stuff, only required if we do <component :is="..."> and stuff (?)
});





Vue.component("v-text-field", VTextField);

import VCurrencyField from "v-currency-field";
Vue.use(VCurrencyField, {
	locale: "fr-CA",
	decimalLength: 2,
	autoDecimalMode: true,
	min: 0,
	max: null,
	defaultValue: 0,
    valueAsInteger: false,
    allowNegative: false,
})



import { KbUtilsPlugin } from "@/classes/KbUtils.js";
Vue.use(KbUtilsPlugin, {});


import store from "@/store";
import router from "@/router";
import {sync} from "vuex-router-sync";
sync(store, router);


import i18n from "@/loc/kb-loc-setup";


import "@/components/base/autoload.js";
import App from "./App.vue";



import { default as vuelidate, validationMixin } from "vuelidate";
Vue.use(vuelidate);

Vue.mixin({
	methods: {
		formatCurrency: function (val, currency = null) {
			val = Number(val);
			return `${val.toFixed(2)}${currency ? ` ${currency}` : ''}`;
		},
	},
})

Vue.filter('striphtml', function (value) {
	var div = document.createElement("div");
	div.innerHTML = value;
	var text = div.textContent || div.innerText || "";
	return text;
});




const vuetify = new Vuetify({
	lang: {
		locales: {fr:lang_fr, en:lang_en},
		current: "fr",
	},
	breakpoint: {
		mobileBreakpoint: "sm", //By default it's "md" ?! https://vuetifyjs.com/en/features/breakpoints/#mobile-breakpoints
	},
	theme: {
		dark: false,
		themes: {
			light: {
				primary: colors.blue.base,
				secondary: colors.grey.darken1,
				accent: colors.blue.lighten4,
				background: colors.grey.lighten2
			},
			dark: {}
		},
		options: { customProperties: true },
	},
	/*
	NOTE on importing Ripple:
		Tentative to solve bug on defining data table's <template #item.data-table-select> slot.
		Declaring that slot yields the following bug "vue.runtime.esm.js:620 [Vue warn]: Failed to resolve directive: ripple (found in <KbModuleList> at src/components/modules/KbModuleList.vue)"
		https://github.com/DoritoBandito/vuetify-upload-button/issues/14#issuecomment-460504577
		https://github.com/vuetifyjs/vuetify/issues/8096#issuecomment-712247395
		... nothing seems to correct that for nows, but it doesn't break the app, so...
	*/
	directives: {Ripple}
});



Vue.config.productionTip = false;
new Vue({
	router,
	store,
	i18n,
	mixins: [validationMixin],
	vuetify,
	render: h => h(App)
}).$mount("#app");


export { vuetify };
