<template>
	
	<v-container class="fill-height">

		<v-row v-if="!firstLoad">
			<v-col lg="6" offset-lg="3" md="8" offset-md="2" sm="8" offset-sm="2" >
				<v-card v-if="cart.products.length < 1" class="mx-auto rounded-0 pa-5 text-center" flat>
					<v-list-item-title>{{ $t('cartIsEmpty') }}</v-list-item-title>
				</v-card>
				<template v-else>
					<v-card class="mx-auto rounded-0 pa-1 mb-7" flat>
						<v-list class="pa-0">
							<template v-for="loop_product in cart.products">
								<v-fade-transition :key="loop_product.variation.pk">
									<v-col class="pa-0">
										<div :key="`0_${loop_product.variation.pk}`" class="text-right" v-if="$store.state.user.isLoggedIn" >
											<v-btn icon small :loading="(typeof removeLoading[loop_product.variation.pk] != undefined) && removeLoading[loop_product.variation.pk]" @click="removeVariation(loop_product.variation.pk)">
												<v-icon class="error--text text-h6">mdi-close-circle</v-icon>
											</v-btn>
										</div>

										<v-list-item :key="`1_${loop_product.variation.pk}`">
											<v-list-item-avatar tile size="125" class="kb-link" color="accent" @click="$router.push({ path: '/product', query: { id:loop_product.pk } })">
												<v-img v-if="loop_product.image" max-height="125" max-width="125" :src="loop_product.image" />
												<v-icon v-else x-large>mdi-image-broken-variant</v-icon>
											</v-list-item-avatar>
											<v-list-item-content>
												<v-list-item-subtitle>
													<div class="text-subtitle-1 primary--text kb-one-line skus">
														<kb-product-fav-icon v-model="loop_product.isFavorite" :id-product="loop_product.pk" />
														{{ $t('item') }} {{ loop_product.variation.sku }}
													</div>
												</v-list-item-subtitle>
												<v-list-item-title class="kb-two-lines">
													<router-link class="secondary--text text-h6 font-weight-bold text-decoration-none" :to="{ path: '/product', query: { id:loop_product.pk } }">{{ loop_product.name }}</router-link>
												</v-list-item-title>
												<v-list-item-subtitle>
													<div class="text-subtitle-1 kb-one-line">{{ loop_product.variation.name }}</div>
												</v-list-item-subtitle>
											</v-list-item-content>
										</v-list-item>
										<v-list-item :key="`2_${loop_product.variation.pk}`">
											<v-row>
												<v-col cols="4" class="secondary--text subtitle-2">
													{{ $t('qty') }}
													<br><v-text-field outlined dense class="rounded-0 kb-qty" type="number" v-model="loop_product.qty" :min="0" @input="updateQty(loop_product)"/>
												</v-col>
												<v-col cols="4" class="text-right secondary--text subtitle-2">
													{{ $t('unitPrice') }}
													<br><span class="subtitle-1">
														<template v-if="isVariationDiscounted(loop_product)">
															<span class="text-decoration-line-through">{{ formatCurrency(loop_product.variation.regularPrice, "$") }}</span> <br /><span class="red--text">{{ formatCurrency(loop_product.variation.userPrice, "$") }}</span>
														</template>
														<template v-else>
															{{ formatCurrency(loop_product.variation.userPrice, "$") }}
														</template>
													</span>
												</v-col>
												<v-col cols="4" class="text-right secondary--text subtitle-2">
													{{ $t('subTotal') }}
													<br><span class="subtitle-1">{{ (loop_product.variation.userPrice * Math.max(loop_product.qty,0)).toFixed(2) }} $</span>
												</v-col>
											</v-row>
										</v-list-item>
										<v-col :key="`div_${loop_product.variation.pk}`" cols="8" offset="2">
											<v-divider class="secondary--text kb-divider-cart" />
										</v-col>
									</v-col>
								</v-fade-transition>
							</template>
							<v-list-item>
								<v-col cols="8" class="text-right secondary--text pb-0">{{ $t('subTotal') }}</v-col>
								<v-col cols="4" class="text-right secondary--text pb-0">
									<template v-if="!updateRunning">{{ formatCurrency(cart.subTotal, "$") }}</template>
									<v-progress-circular v-else indeterminate size="18" />
								</v-col>
							</v-list-item>
							<v-list-item>
								<v-col cols="8" class="text-right secondary--text pt-0">{{ $t('taxes') }}</v-col>
								<v-col cols="4" class="text-right secondary--text pt-0">
									<template v-if="!updateRunning">{{ formatCurrency(cart.taxes, "$") }}</template>
									<v-progress-circular v-else indeterminate size="18" />
								</v-col>
							</v-list-item>
							<v-list-item>
								<v-col cols="8" class="text-right secondary--text font-weight-bold">{{ $t('total') }}</v-col>
								<v-col cols="4" class="text-right secondary--text font-weight-bold">
									<template v-if="!updateRunning">{{ formatCurrency(cart.total, "$") }}</template>
									<v-progress-circular v-else indeterminate size="20" />
								</v-col>
							</v-list-item>
						</v-list>
					</v-card>
					
					<span class="subtitle-2 mt-10 secondary--text">{{ $t('deliveryAddress') }}</span>
					<v-select v-model="selectedDeliveryAddress" :items="deliveryAddresses" item-text="addressName" item-value="pk" background-color="white" />
					
					<span class="subtitle-2 mt-10 secondary--text">{{ $t('notes') }}</span>
					<v-textarea outlined class="rounded-0 mt-2 secondary--text" background-color="white" color="primary" v-model="cart.notes" />
					<v-row v-if="errMessage" class="my-1" justify="center">
						<v-alert outlined type="error" text>{{ errMessage }}</v-alert>
					</v-row>
					<v-col>
						<v-btn color="primary" width="100%" class="py-6" @click="order" :disabled="updateRunning || orderRunning" :loading="orderRunning">{{ $t('sendOrder') }}</v-btn>
					</v-col>
				</template>
			</v-col>
		</v-row>
		
		<v-dialog :value="pkOrder>0" width="20rem" persistent>
			<v-card>
				<v-card-title class="justify-center py-5 pb-0 ma-0">
					<v-icon color="primary" class="kb-icon-check">mdi-check</v-icon>
				</v-card-title>
				<v-card-title class="justify-center pa-0 ma-0 secondary--text text-h4">{{ $t('thanks') }}</v-card-title>
				<v-card-title class="justify-center pa-0 ma-0 secondary--text subtitle-1">{{ $t('successfullySent') }}</v-card-title>
				<v-card-text class="text-center ma-0 pa-0">{{ $t('yourOrderNo') }}</v-card-text>
				<v-card-text class="text-center ma-0 pa-0 primary--text">#{{ pkOrder }}</v-card-text>
				<v-card-text class="text-center secondary--text mt-5 px-8">{{ $t('willContactYou') }}</v-card-text>

				<v-card-actions class="px-7">
					<v-btn color="primary" class="kb-btn-dialog-success" to="/catalog">{{ $t('goToCatalog') }}</v-btn>
				</v-card-actions>
				<v-card-actions class="pa-7 pt-2">
					<v-btn color="primary" text outlined class="kb-btn-go-catalog kb-btn-dialog-success" to="/orders">{{ $t('viewOrders') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</v-container>
	
</template>
<script>
	
	import KbApi from "@/classes/KbApi.js";
	import KbEmail from "@/classes/KbEmail.js";

	export default {
		i18n: {
			messages: {
				fr: {
					cartIsEmpty: `Votre panier est vide.`,
					item: `Item`,
					qty: `Quantité`,
					unitPrice: `Prix unitaire`,
					subTotal: `Sous-total`,
					taxes: `Taxes`,
					total: `Total`,
					deliveryAddress: `ADRESSE DE LIVRAISON`,
					notes: `NOTES`,
					sendOrder: `PASSER LA COMMANDE`,
					thanks: `Merci!`,
					successfullySent: `Votre commande a bien été envoyé.`,
					yourOrderNo: `Votre numéro de commande:`,
					willContactYou: `Un membre de notre équipe révisera votre commande et prendra contact avec vous dès qu'elle sera expédiée.`,
					goToCatalog: `Revenir au catalogue`,
					viewOrders: `Voir mes commandes`,
				},
				en: {
					cartIsEmpty: `Your cart is empty.`,
					item: `Item`,
					qty: `Quantity`,
					unitPrice: `Unit price`,
					subTotal: `Subtotal`,
					taxes: `Taxes`,
					total: `Total`,
					deliveryAddress: `Delivery address`,
					notes: `Notes`,
					sendOrder: `Send order`,
					thanks: `Thanks !`,
					successfullySent: `Your order was successfully placed.`,
					yourOrderNo: `Your order number:`,
					willContactYou: `A member of our team will review your order and contact you when it's shipped`,
					goToCatalog: `Go to catalog`,
					viewOrders: `View my orders`,
				},
			}
		},
		components: {
			KbProductFavIcon: () => import("@/components/general/KbProductFavIcon.vue"),
		},
		data() {
			return {
				firstLoad:true,
				updateRunning:true,
				cart:{},
				removeLoading:[],
				orderRunning: false,
				pkOrder:0,
				deliveryAddresses:[],
				selectedDeliveryAddress: null,
				errMessage:"",
			}
		},
		async mounted()
		{
			this.updateRunning = true;
			
			await this.loadDeliveryAddresses();
			await this.loadCart();
			
			this.updateRunning = false;
			this.firstLoad = false;
		},
		methods: {
			order() {
				this.apiOrder();
			},
			async apiOrder()
			{
				this.orderRunning = true;
				
				this.errMessage = "";
				
				try {
					const data = await KbApi.post('/api/orders', {selectedDeliveryAddress:this.selectedDeliveryAddress, notes:this.cart.notes}, this.$store.state.user);
					this.pkOrder = data;
					this.$store.dispatch("user_cartQty", 0);
				} catch (error) {
					this.errMessage = this.$KbUtils.parseApiError(error);
				}
				
				this.orderRunning = false;
			},
			isVariationDiscounted(product) { return product.variation.regularPrice!==product.variation.userPrice; },
			updateCart() {
				this.apiUpdateCart()
			},
			async apiUpdateCart()
			{
				while(this.updateRunning) {
        			await new Promise(resolve => setTimeout(resolve, 500));
				}
				this.updateRunning = true;

				const body = {
					pk: this.cart.pk,
					notes: this.cart.notes,
				};

				let data;
				try {
					data = await KbApi.patch('/api/cart', body, this.$store.state.user);
					this.cart = data;
					this.$store.dispatch("user_cartQty", data.products.length)
				} catch (error) {
					this.errMessage = this.$KbUtils.parseApiError(error);
				} finally {
					this.updateRunning = false;
				}
			},
			updateQty(product) {
				product.qty = Math.max(product.qty, 1);
				this.apiSetQty(product)
			},
			async apiSetQty(product)
			{
				while(this.updateRunning) {
        			await new Promise(resolve => setTimeout(resolve, 500));
				}
				this.updateRunning = true;

				const body = {
					variation_pk: product.variation.pk,
					qty: product.qty
				};

				let data;
				try {
					data = await KbApi.post('/api/cart/setQtyProduct', body, this.$store.state.user);
					this.cart = data;
					this.$store.dispatch("user_cartQty", data.products.length)
				} catch (error) {
					this.errMessage = this.$KbUtils.parseApiError(error);
				} finally {
					this.updateRunning = false;
				}
			},
			removeVariation(variationPk) {
				this.removeLoading[variationPk] = true;
				this.removeLoading.splice(variationPk, 1, true);

				this.apiRemoveVariation(variationPk);
			},
			async apiRemoveVariation(variationPk)
			{
				while(this.updateRunning) {
        			await new Promise(resolve => setTimeout(resolve, 500));
				}
				this.updateRunning = true;
				const body = {
					variation_pk: variationPk,
				};

				let data;
				try {
					data = await KbApi.post('/api/cart/removeProduct', body, this.$store.state.user);
					this.removeLoading[variationPk] = false;
					this.removeLoading.splice(variationPk, 1, false);
					this.cart = data;
					this.$store.dispatch("user_cartQty", data.products.length)
				} catch (error) {
					this.errMessage = this.$KbUtils.parseApiError(error);
				} finally {
					this.updateRunning = false;
				}
			},
			async loadDeliveryAddresses()
			{
				try {
					const data = await KbApi.get(`/api/users/0`, this.$store.state.user);
					const deliveryAddresses = [];
					data.user.deliveryAddresses.forEach(loop_deliveryAddress =>
					{
						deliveryAddresses.push({
							pk: loop_deliveryAddress.pk.toString(),
							addressName: loop_deliveryAddress.addressName,
						});
					});
					this.deliveryAddresses = deliveryAddresses;
				} catch (error) {
					this.errMessage = this.$KbUtils.parseApiError(error);
				}
			},
			async loadCart()
			{
				try {
					const data = await KbApi.get('/api/cart', this.$store.state.user);
					this.cart = data;
					
					const firstDeliveryAddressPK = this.deliveryAddresses.length>0 ? this.deliveryAddresses[0].pk.toString() : null;
					this.selectedDeliveryAddress = this.cart.deliveryAddress?.pk?.toString() ?? firstDeliveryAddressPK;
					this.$store.dispatch("user_cartQty", data.products.length)
				} catch (error) {
					this.errMessage = this.$KbUtils.parseApiError(error);
				}
			},
		},
	};
	
</script>

<style scoped>
	
	.v-divider.kb-divider-cart {
		border-width: 1px;
		border-color: var(--secondary--text);
	}

	.kb-qty {
		width: 80px;
	}

	.kb-icon-check {
		font-size: 12rem;
	}

	.kb-btn-go-catalog {
		border-color:var(--v-primary-base) !important;
	}

	.kb-btn-dialog-success {
		height: 3rem !important;
		width: 100%;
		text-transform: uppercase;
	}

	.kb-link:hover {
		cursor: pointer;
	}
	
	.v-select >>> .v-select__slot {
		padding: 10px;
	}
	
</style>