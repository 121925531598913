import KbApi from "@/classes/KbApi.js";
import KbUtils from "@/classes/KbUtils.js";

export default class KbEmail
{
    static async sendToAll(subject, content, recipients, auth)
    {
		try
		{
			const response = await KbApi.post('/api/users/sendEmailToAll', {
				subject,
				content,
				recipients, // arr of strings or of {'email', 'name', 'tags'}
			}, auth);
		}
		catch (error)
		{
			return KbUtils.parseApiError(error);
		}

		return null;
	}
}