<template>
	
	<v-container class="fill-height">
		<v-row>
			<v-col>
				<v-badge color="primary" overlap :content="filter.filterCount" :value="filter.filterCount">
				<v-btn color="secondary" @click="on_filter">
					<v-icon>mdi-filter</v-icon>
					<span class="hidden-sm-and-down">{{ $t('filter') }}</span>
				</v-btn>
				</v-badge>
			</v-col>
		</v-row>

		<v-row v-if="pageLoading" justify="center">
			<v-progress-circular :size="200" color="primary" indeterminate class="my-16" />
		</v-row>
		<v-row v-else>
			<v-col v-for="(loop_product, idProduct) in products" :key="idProduct" lg="4" md="6" sm="12">
				<v-card class="mx-auto rounded-0 pa-2 pb-5" flat>
					<v-list-item>
						<v-list-item-avatar tile size="100" color="accent">
							<v-img v-if="loop_product.image" max-height="100" max-width="100" :src="loop_product.image" />
							<v-icon v-else x-large>mdi-image-broken-variant</v-icon>
						</v-list-item-avatar>
						<v-list-item-content>
							<div class="text-right" v-if="$store.state.user.isLoggedIn">
								<kb-product-fav-icon v-model="loop_product.isFavorite" :id-product="loop_product.pk" />
							</div>
							<v-spacer />
							<v-list-item-title class="secondary--text text-h6 font-weight-bold kb-two-lines">{{ loop_product.name }}</v-list-item-title>
							<v-spacer />
							<div class="overline primary--text kb-one-line skus">{{ loop_product.skus }}</div>
						</v-list-item-content>

					</v-list-item>
					<v-list-item>
						<v-list-item-subtitle class="kb-three-lines">{{ loop_product.desc | striphtml }}</v-list-item-subtitle>
					</v-list-item>

					<v-card-actions>
						<v-btn depressed color="primary" width="100%" class="py-6" @click="$router.push({ path: '/product', query: { id:loop_product.pk } })">{{ $t('moreInfo') }}</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>

		<v-row v-if="errMessage" class="mt-5" justify="center">
			<v-col class="py-0">
				<v-alert outlined type="error" text>{{ errMessage }}</v-alert>
			</v-col>
		</v-row>

		<v-row justify="center">
			<v-pagination v-model="paging.currentPage" :length="paging.pageCount" :total-visible="7" @input="goToPage(paging.currentPage)" />
		</v-row>		
		
	</v-container>
	
</template>
<script>
	
	const DEFAULT_PAGE_SIZE = 12; //Change in server's RouteParser_Products::DEFAULT_PAGE_SIZE & @/views/modules/catalog/index.vue::DEFAULT_PAGE_SIZE
	
	import KbApi from "@/classes/KbApi.js";

	export class KbModuleList_Filters
	{
		_title = null;
		_search = "";
		_categories = [];
		_favoriteProducts = false;
		_alreadyOrder = false;
		_pageSize = null;
		_sort = null;
		_pageSizeDefault = null;
		_sortDefault = null;

		_update = false;
		
		constructor(title, pageSizeDefault, sortDefault)
		{
			this._title = title;
			this._pageSize = this._pageSizeDefault = pageSizeDefault;
			this._sort = this._sortDefault = sortDefault;
		}
		
		get title() { return this._title; }
		set title(val) { this._title=val; }

		get search() { return this._search; }
		set search(val) { this._search=val; }

		get categories() { return this._categories; }
		set categories(val) { this._categories=val; }

		get favoriteProducts() { return this._favoriteProducts; }
		set favoriteProducts(val) { this._favoriteProducts=val; }

		get alreadyOrder() { return this._alreadyOrder; }
		set alreadyOrder(val) { this._alreadyOrder=val; }

		get pageSize() { return this._pageSize; }
		set pageSize(val) { this._pageSize=val; }

		get sort() { return this._sort; }
		set sort(val) { this._sort=val; }

		get pageSizeDefault() { return this._pageSizeDefault; }

		get filterCount() {
			let count = 0;

			if(this._search) { count++; }
			if(this._categories.length > 0 ) { count++; }
			if(this.favoriteProducts) { count++; }
			if(this.alreadyOrder) { count++; }

			return count;
		}

		get hasNoFilterActive() {
			return this.filterCount === 0 && this._pageSize === this._pageSizeDefault && this._sort === this._sortDefault;
		}

		get update() { return this._update; }
		set update(val) { this._update=val; }

		loadFromQsa(qsa) {
			let temp;
			this._search = typeof (temp = qsa.search) !== 'undefined' ? temp : "";
			this._categories = typeof (temp = qsa.categories) !== 'undefined' ? temp.split(',') : [];
			this._favoriteProducts = typeof qsa.favoriteProducts !== 'undefined';
			this._alreadyOrder = typeof qsa.alreadyOrder !== 'undefined';
			this._pageSize = typeof (temp = qsa.pageSize) !== 'undefined' && (temp = parseInt(temp)) !== 'NaN' && temp > 0 ? temp : this._pageSizeDefault;

			const arrSort = this._sortDefault.split('.');
			const sortBy = typeof (temp = qsa.sortBy) !== 'undefined' ? temp : arrSort[0];
			const sort = typeof (temp = qsa.sort) !== 'undefined' ? temp : arrSort[1];

			this._sort = `${sortBy}.${sort}`;
		}

		exportToQsa() {
			const qsa = {};

			if(this._search) { qsa.search = this._search; }
			if(this._categories.length > 0) { qsa.categories = this._categories.sort().join(','); }
			if(this._favoriteProducts) { qsa.favoriteProducts = true; }
			if(this._alreadyOrder) { qsa.alreadyOrder = true; }
			if(this._pageSize !== this._pageSizeDefault) { qsa.pageSize = this._pageSize; }
			if(this._sort !== this._sortDefault) {
				const arrSort = this._sort.split('.');
				const arrSortDefault = this._sortDefault.split('.');
				if(arrSort[0] !== arrSortDefault[0]) { qsa.sortBy = arrSort[0]; }
				if(arrSort[1] !== arrSortDefault[1]) { qsa.sort = arrSort[1]; }
			}

			return qsa;
		}
	};
	
	export default {
		i18n: {
			messages: {
				fr: {
					filter: `Filtrer`,
					moreInfo: `Plus d'informations`,
				},
				en: {
					filter: `Filter`,
					moreInfo: `More informations`,
				},
			}
		},
		components: {
			KbProductFavIcon: () => import("@/components/general/KbProductFavIcon.vue"),
		},
		data() {
			return {
				pageLoading:true,
				products:[],
				paging:{},
				filter: new KbModuleList_Filters('Filtres', DEFAULT_PAGE_SIZE, 'name.asc'),
				lastQuery: null,
				errMessage: "",
			}
		},
		watch: {
			'filter.update': function () {
				this.goToPage();
			}
		},
		mounted()
		{
			this.filter.loadFromQsa(this.$route.query);
			let pageIndexFromQsa = this.$route.query.pageIndex;
			this.paging.pageIndex = (typeof pageIndexFromQsa !== 'undefined') && (pageIndexFromQsa = parseInt(pageIndexFromQsa, 10)) > 1 ? pageIndexFromQsa : 1;
			this.loadProducts();
			this.lastQuery = this.filter.exportToQsa();
		},
		methods: {
			on_filter() { this.$store.dispatch("rightDrawer_showModuleListFilters", this.filter); },
			goToPage(page = 1) {
				const query = this.filter.exportToQsa();
				this.paging.pageIndex = page
				if(page > 1) { query.pageIndex = page; }
				
				if(page < 2 && this.filter.hasNoFilterActive) {
					if(Object.keys(this.$route.query).length > 0) { this.$router.push({ path: '/catalog' }); }
				}
				else if(JSON.stringify(query) !== JSON.stringify(this.lastQuery)) {
					this.$router.push({ query: query });
					this.lastQuery = query;
				}

				this.loadProducts();
			},
			async loadProducts()
			{
				this.pageLoading = true;

				const qsa = this.filter.exportToQsa();

				if(this.paging.pageIndex > 1) {
					qsa.pageIndex = this.paging.pageIndex;
				}

				try {
					const data = await KbApi.get('/api/products', this.$store.state.user, qsa);
					this.products = data.items;
					this.paging = data.paging;
				} catch (error) {
					this.errMessage = this.$KbUtils.parseApiError(error);
				} finally {
					this.pageLoading = false;
				}
			},
		},
	};
	
</script>

<style scoped>
	
	.v-list-item__title {
		height: 50px;
	}

	.v-list-item__subtitle {
		height: 70px;
		line-height: 1.5rem;
	}

	.skus {
		height: 20px;
	}
	
</style>
